<template>
  <div>
    <v-container fluid class="pt-10">
      <v-row no-gutters justify="center" class="pb-10">
        <!-- 타이틀 -->
        <v-col
          cols="12"
          class="pt-4 pb-8 reform_primary--text text-center text-h5 font-weight-bold"
          >{{ directiveInfo.title }}
        </v-col>
        <v-col cols="12" class="text-center font-weight-medium pb-10 text-h6">
          <div>
            {{ directiveInfo.content[0] }}
          </div>
        </v-col>

        <v-col cols="12" md="8" lg="8" xl="8" class="font-weight-medium pb-10">
          <v-divider></v-divider>
          <v-row class="pl-5 pr-5 mt-10" no-gutters>
            <v-col cols="12">
              <div>* {{ directiveInfo.content[1] }}</div>
            </v-col>
            <v-col cols="12">
              <div class="mt-2">* {{ directiveInfo.content[2] }}</div>
            </v-col>
            <v-col cols="12">
              <div class="mt-2">* {{ prPageInfo.title }}</div>
            </v-col>
            <v-col cols="12">
              <div class="mt-2 ml-5 mr-5">
                <span class="font-weight-bold">{{
                  prPageInfo.content[0].title
                }}</span>
                <span>{{ prPageInfo.content[0].content[0] }}</span>
                <span class="grey--text">{{
                  prPageInfo.content[0].content[1]
                }}</span>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="mt-2 ml-5 mr-5">
                <span class="font-weight-bold">{{
                  prPageInfo.content[1].title
                }}</span>
                <span>{{ prPageInfo.content[1].content }}</span>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="mt-2 ml-5 mr-5">
                <span class="font-weight-bold">{{
                  prPageInfo.content[2].title
                }}</span>
                <span>{{ prPageInfo.content[2].content[0] }}</span>
                <span class="grey--text">{{
                  prPageInfo.content[2].content[1]
                }}</span
                ><br />
                <span class="red--text font-weight-bold">
                  {{ prPageInfo.content[2].content[2] }}
                </span>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="mt-2">* {{ directiveInfo.content[3] }}</div>
            </v-col>
          </v-row>
          <v-row justify="center" align="center" class="pt-2">
            <v-btn
              @click="movePaymentDesign()"
              x-large
              rounded
              dark
              elevation="0"
              color="reform_orange"
            >
              디자인 제작 의뢰하기
            </v-btn>
          </v-row>
        </v-col>

        <!-- 타이틀끝 -->
        <!-- 테이블 -->
        <v-col cols="12" md="8" lg="8" xl="8">
          <v-container fluid class="pa-0 custom-table">
            <!-- 샘플보기 -->
            <!-- <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            > -->
            <!-- <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>샘플보기</div>
              </v-col> -->
            <!-- <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 text-h6 custom-table-cell background-cell"
              >
                <v-row no-gutters class="pa-0 ma-0">
                  <v-col cols="5">
                    <v-select
                      dense
                      hide-details
                      color="reform_primary"
                      item-color="reform_primary"
                      :items="form.templateType.options"
                      value="A형"
                      v-model="form.templateType.value"
                      ref="templateType"
                      @input="isTemplate(form.templateType.value)"
                    ></v-select>
                    <p class="warn-message" v-if="form.templateType.hasError">
                      {{ form.templateType.errorTxt }}
                    </p>
                  </v-col>
                  <v-col cols="7">
                    <v-row no-gutters justify="end">
                      <v-btn
                        @click="isSampleView()"
                        rounded
                        dark
                        elevation="0"
                        color="reform_primary"
                        class="ml-2"
                      >
                        샘플 보기
                      </v-btn> -->
            <!-- <v-btn
                        @click="isSampleView_thanq()"
                        rounded
                        dark
                        elevation="0"
                        color="reform_primary"
                        class="ml-2"
                        v-if="form.templateType.value === 'B형'"
                      >
                        샘플 보기(2)
                      </v-btn> -->
            <!-- </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row> -->
            <!-- 사진첨부탭 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
              v-for="(attachmentItem, index) in form.attachment"
              :key="index + 'A'"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <v-row no-gutters class="ma-0 pa-0">
                  <v-col cols="12">
                    <div>{{ attachmentItem.title }}</div>
                  </v-col>
                  <v-col cols="12">
                    <v-subheader class="ma-0 pa-0" v-if="index === 0"
                      >권장 이미지 비율 (1:1)</v-subheader
                    >
                    <v-subheader class="ma-0 pa-0" v-else-if="index === 1"
                      >권장 이미지 비율 (가로형태)</v-subheader
                    >
                    <v-subheader class="ma-0 pa-0" v-else-if="index === 2"
                      >권장 이미지 비율 (16:9)</v-subheader
                    >
                    <v-subheader class="ma-0 pa-0" v-else-if="index === 3"
                      >권장 이미지 비율 (1:1)</v-subheader
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 custom-table-cell background-cell"
              >
                <v-container fluid class="pa-0 ma-0">
                  <v-row no-gutters class="pa-0 ma-0">
                    <v-col cols="8">
                      <v-text-field
                        hide-details
                        dense
                        color="reform_primary"
                        v-model="attachmentItem.filename"
                        @click="fiClick(index)"
                      >
                      </v-text-field>
                      <input
                        style="display: none"
                        class="file-upload"
                        :id="'fi_' + index"
                        type="file"
                        @change="onFileChange(index, $event)"
                        accept="image/gif, image/jpeg, image/png"
                      />
                    </v-col>
                    <v-col cols="4" class="text-right">
                      <v-btn
                        class="ml-2"
                        color="reform_primary"
                        @click="deletePicture(index)"
                        elevation="0"
                        rounded
                        dark
                      >
                        첨부 삭제
                      </v-btn>
                    </v-col>
                    <v-col cols="12">
                      <v-subheader
                        class="ml-0 pa-0 ma-0"
                        v-html="computedLine(attachmentItem.warn)"
                        v-if="attachmentItem.filename === ''"
                      ></v-subheader>
                      <v-img
                        v-else
                        class="mt-5"
                        max-height="300px"
                        contain
                        :src="attachmentItem.url"
                        @click="download(index)"
                      >
                      </v-img>
                    </v-col>
                  </v-row>
                </v-container>
                <v-img> </v-img>
              </v-col>
            </v-row>
            <!-- 전화번호 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>전화번호</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 custom-table-cell background-cell"
              >
                <v-row align="center" justify="start" no-gutters>
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    placeholder="전화번호를 입력해주세요"
                    v-model="form.tel.value"
                    @keyup="checkTel()"
                    @blur="checkTel()"
                    maxlength="13"
                  ></v-text-field>
                </v-row>
              </v-col>
            </v-row>
            <!-- 팩스번호 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>팩스번호</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 custom-table-cell background-cell"
              >
                <v-row align="center" justify="start" no-gutters>
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    placeholder="팩스번호를 입력해주세요"
                    v-model="form.fax.value"
                    @keyup="checkFax()"
                    @blur="checkFax()"
                    maxlength="13"
                  ></v-text-field>
                </v-row>
              </v-col>
            </v-row>
            <!-- 병원소개 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>병원소개</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 custom-table-cell background-cell"
              >
                <v-row align="center" justify="start" no-gutters>
                  <v-col cols="12">
                    <v-textarea
                      hide-details
                      outlined
                      dense
                      v-model="form.introduceHospital.value"
                      @keyup="checkIntroduceHospital($event)"
                      @blur="checkIntroduceHospital($event)"
                      maxlength="100"
                      no-resize
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- 병원소개 상세 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>병원소개 상세</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 custom-table-cell background-cell"
              >
                <v-row align="center" justify="start" no-gutters>
                  <v-col cols="12">
                    <v-textarea
                      hide-details
                      dense
                      outlined
                      v-model="form.introduceHospitalDetail.value"
                      @keyup="checkIntroduceHospitalDetail($event)"
                      @blur="checkIntroduceHospitalDetail($event)"
                      maxlength="200"
                      no-resize
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- 의사소개 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>의사소개</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 custom-table-cell background-cell"
              >
                <v-row align="center" justify="start" no-gutters>
                  <v-col cols="12">
                    <v-textarea
                      hide-details
                      dense
                      outlined
                      v-model="form.introduceDoctor.value"
                      @keyup="checkIntroduceDoctor($event)"
                      @blur="checkIntroduceDoctor($event)"
                      maxlength="200"
                      no-resize
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- 의사약력 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>의사약력</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 custom-table-cell background-cell"
              >
                <v-row align="center" justify="start" no-gutters>
                  <v-col cols="12">
                    <v-textarea
                      hide-details
                      dense
                      outlined
                      v-model="form.historyDoctor.value"
                      @keyup="checkHistoryDoctor($event)"
                      @blur="checkHistoryDoctor($event)"
                      maxlength="200"
                      no-resize
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- 진료시간 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>진료시간</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 custom-table-cell background-cell"
              >
                <v-row align="center" justify="start" no-gutters>
                  <v-col cols="12">
                    <v-textarea
                      hide-details
                      dense
                      outlined
                      v-model="form.treatmentTime.value"
                      @keyup="checkTreatmentTime($event)"
                      @blur="checkTreatmentTime($event)"
                      maxlength="200"
                      no-resize
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- 홈페이지 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>홈페이지</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 custom-table-cell background-cell"
              >
                <v-row align="center" justify="start" no-gutters>
                  <v-col cols="12">
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      placeholder="홈페이지를 입력해주세요"
                      v-model="form.homepage.value"
                      @keyup="checkHomepage()"
                      @blur="checkHomepage()"
                      maxlength="50"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <div>
                      **기존에 보유하고 계신 병원 홈페이지가 존재하는 경우,
                      링크를 입력해주시기 바랍니다.
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- 기타사항 -->
            <!-- <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>기타사항</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 custom-table-cell background-cell"
              >
                <v-container fluid class="ma-0 pa-0">
                  <v-row
                    align="center"
                    justify="start"
                    no-gutters
                    v-for="(subPromotion, index) in form.subPromotionPageList
                      .item"
                    :key="index"
                    class="mt-1 mb-1"
                  >
                    <v-col cols="6">
                      <v-select
                        class="mr-3"
                        color="reform_primary"
                        outlined
                        hide-details
                        dense
                        :items="form.subPromotionPageList.options"
                        v-model="form.subPromotionPageList.item[index].type"
                        ref="etc"
                        @input="isEtc(index)"
                      ></v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        dense
                        hide-details
                        v-model="form.subPromotionPageList.item[index].value"
                        @keyup="checkEtcPage(index)"
                        @blur="checkEtcPage(index)"
                        maxlength="50"
                        :disabled="
                          form.subPromotionPageList.item[index].type ===
                            '선택안함' ||
                          form.subPromotionPageList.item[index].type ===
                            '항목 선택'
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row> -->
            <!-- 예약 웹/앱 주소 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>예약 웹/앱주소</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 custom-table-cell background-cell"
              >
                <v-row align="center" justify="start" no-gutters>
                  <v-col cols="12">
                    <v-text-field
                      outlined
                      dense
                      hide-details
                      placeholder="예약 앱/웹주소를 입력해주세요"
                      v-model="form.resvPage.value"
                      @keyup="checkReservationPage()"
                      @blur="checkReservationPage()"
                      maxlength="200"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- 시안선택 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>시안 선택</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 custom-table-cell background-cell"
              >
                <v-row align="center" justify="start" no-gutters>
                  <v-col cols="12">
                    <v-radio-group
                      class="mt-0 pt-0 mb-5 pr-manage-radio"
                      v-model="radioValues"
                      dense
                      hide-details=""
                      row
                      @change="change_radio(radioValues)"
                    >
                      <v-radio
                        color="reform_primary"
                        label="A형"
                        value="5"
                      ></v-radio>
                      <v-radio
                        color="reform_primary"
                        label="B형"
                        value="4"
                      ></v-radio>
                      <v-radio
                        color="reform_primary"
                        label="C형"
                        value="3"
                      ></v-radio>
                      <!-- <v-radio
                        color="reform_primary"
                        label="D형"
                        value="1"
                      ></v-radio>
                      <v-radio
                        color="reform_primary"
                        label="E형"
                        value="2"
                      ></v-radio> -->
                    </v-radio-group>
                    <div class="pr-manage-sam-pre-div">
                      <v-btn
                        rounded
                        elevation="0"
                        color="reform_primary"
                        dark
                        class="pr-manage-sample-btn"
                        @click="isSampleView()"
                      >
                        {{
                          radioValues === "5"
                            ? "A"
                            : radioValues === "4"
                            ? "B"
                            : radioValues === "3"
                            ? "C"
                            : ""
                        }}형 샘플보기
                      </v-btn>
                      <v-btn
                        @click="movePaymentDesign()"
                        rounded
                        elevation="0"
                        color="reform_orange"
                        dark
                        class="pr-manage-sample-btn"
                        v-if="radioValues === '3'"
                      >
                        디자인 제작 의뢰하기
                      </v-btn>
                      <!-- <v-btn
                        rounded
                        elevation="0"
                        color="#6fdaf1"
                        dark
                        class="pr-manage-sample-btn"
                        @click="preview('null')"
                        v-if="form.sampleType.value !== '' && form.sampleType.value !== null"
                      >
                        {{ radioValues ==="5" ? "A" : 
                            radioValues ==="4" ? "B" : 
                            radioValues ==="3" ? "C" : "" 
                        }}형 미리보기
                      </v-btn> -->
                    </div>
                  </v-col>
                  <v-col cols="12" v-if="radioValues === '3'">
                    <div class="a-input-wrap">
                      <span
                        class="file-name mr-5"
                        v-text="
                          form.attachment[4].filename === null ||
                          form.attachment[4].filename === ''
                            ? '선택된 파일없음'
                            : form.attachment[4].filename
                        "
                      ></span>
                      <input
                        style="display: none"
                        class="prAttachment"
                        id="prAttachment"
                        type="file"
                        @change="onFileChange(4, $event)"
                        accept="image/gif, image/jpeg, image/png"
                      />
                      <v-btn
                        color="reform_primary"
                        rounded
                        dark
                        elevation="0"
                        id="btn_pr_attachment"
                        @click="btn_pr_attachment()"
                      >
                        파일첨부
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- 소개정보 추가 사용유무 -->
            <v-row
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pa-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>소개정보 추가 사용유무</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 custom-table-cell background-cell"
              >
                <v-row align="center" justify="start" no-gutters>
                  <v-col cols="auto">
                    <v-switch
                      @input="checkEditor()"
                      v-model="view.isEditorMenu"
                      color="reform_primary"
                      class="font-weight-bold"
                      inset
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- 소개정보 추가제목 -->
            <v-row
              v-if="view.isEditorMenu"
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pl-5 pr-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>소개정보 추가 제목</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 custom-table-cell background-cell"
              >
                <v-row align="center" justify="start" no-gutters>
                  <v-text-field
                    outlined
                    hide-details
                    placeholder="제목을 입력해주세요"
                    v-model="form.editor.title"
                    maxlength="50"
                  ></v-text-field>
                </v-row>
              </v-col>
            </v-row>
            <!-- 소개정보 추가내용 -->
            <v-row
              v-if="view.isEditorMenu"
              no-gutters
              justify="center"
              align="center"
              class="custom-table-row"
            >
              <v-col
                cols="12"
                md="3"
                lg="3"
                xl="3"
                class="pl-5 pr-5 text-h6 font-weight-bold custom-table-cell"
              >
                <div>소개정보 추가 내용</div>
              </v-col>
              <v-col
                cols="12"
                md="9"
                lg="9"
                xl="9"
                class="pa-5 custom-table-cell background-cell"
              >
                <v-row align="center" justify="start" no-gutters>
                  <div class="editor-wrap form">
                    <editor
                      :editorData="form.editor.content"
                      @sendEditContent="synchronization"
                      class="editor-form"
                    ></editor>
                  </div>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <!-- 테이블끝 -->
      </v-row>
      <v-row justify="center">
        <v-btn
          class="mr-5"
          x-large
          rounded
          width="150px"
          elevation="0"
          color="reform_sky"
          dark
          @click="preview('null')"
          v-if="form.sampleType.value !== '' && form.sampleType.value !== null"
        >
          미리보기
        </v-btn>
        <v-btn
          x-large
          rounded
          width="150px"
          elevation="0"
          color="reform_orange"
          dark
          @click="hyperPdf()"
        >
          출력
        </v-btn>
        <v-btn
          class="ml-5"
          x-large
          rounded
          width="150px"
          elevation="0"
          color="reform_primary"
          dark
          @click="storeInView(view.isModifyMode, radioValues)"
        >
          {{ view.btnName }}
        </v-btn>
      </v-row>
      <button id="focusControl"></button>
    </v-container>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from "@/components/modal/DefaultPopup";
import Editor from "@/views/util/Editor";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
export default {
  components: {
    DefaultPopup,
    Editor,
  },
  data() {
    return {
      radioValues: "",
      sideSet: {
        menuName: "mypage",
        sideTitle: "마이페이지",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
      headerOption: {
        navOrder: 1,
      },
      popupSet: {},
      tab: [
        {
          title: "수신 진료의뢰",
        },
        {
          title: "발신 진료의뢰",
        },
      ],
      pr: {
        logoAttchId: require("@/assets/images/thanqlogo.svg"),
        hospNm: "땡큐서울이비인후과의원",
        hospIntro:
          "지난 한세기 땡큐서울이비인후과의원은 국민의 건강과 생명을 지키고, 우리나라 의학발전을 선도해 왔습니다.",
        conConts:
          "지난 한세기 땡큐서울이비인후과의원은 국민의 건강과 생명을 지키고, 우리나라 의학발전을 선도해 왔습니다.",
        drPicAttchId: require("@/assets/images/doctorHa.jpeg"),
        drNm: "하정훈",
        drHist: "두경부암전공 수술횟수39999건",
        doctIntro:
          "땡큐서울이비인후과의원은 인류의 건강을 수호하는 세계 속의 병원으로 힘차게 전진해 나가고 있으며, 특히 ‘BreakThrough21 대한민국 의료를 세계로’라는 비전을 선포하고 환자중심의 병원으로 거듭나고자 한뜻으로 매진하고 있습니다.",
        addr: "서울 강남구 테헤란로 337(역삼동 705-27)",
        addrDtl: "화남빌딩 2층(외래), 3층(입원ㆍ수술)",
        hospTel: "02-562-5075",
        hospFaxNo: "02-563-5075",
        etcConts:
          "평일 오전9시-오후 6시 (점심시간 오후 1시-2시) 토요일 오전9시-오후1시 일요일·공휴일 휴진",
        mapAttchId: require("@/assets/images/mapmap.png"),
        picAttchId: require("@/assets/images/hsPicture.png"),
        rsvUrl: "www.thanqseoul.com",
        homepage: "www.thanqHome.com",
        etcPage: [
          {
            type: "블로그",
            value: "www.blog.com",
          },
          {
            type: "페이스북",
            value: "www.facebook.com",
          },
          {
            type: "트위터",
            value: "www.twitter.com",
          },
        ],
      },
      memberInfo: {
        hospNm: null,
        drNm: null,
        drId: null,
        addr: null,
        addrDtl: null,
      },
      imageMap: [
        {
          value: null,
        },
        {
          value: null,
        },
        {
          value: null,
        },
        {
          value: null,
        },
      ],
      directiveInfo: {
        title: "소개정보 입력",
        isTitleNewLine: false,
        content: [
          "이 페이지는 회원님의 소개페이지를 작성하기 위한 정보를 입력하는 곳입니다.",
          "회원님의 소개페이지는 회원님 아이디의 웹사이트(www.drrefer.net/dr/회원아이디)에 표시됩니다.",
          "다른 회원님이 회원님께 환자를 의뢰하게 되면 진료의뢰서, 병원 소개페이지(회원 소개페이지) 2장의 출력물을 받게 됩니다.",
          "추가 페이지를 입력할 경우 입력창 하단의 '추가 페이지 사용유무' 클릭 후 입력.",
          "(웹에서만 표시되고 출력 시 해당부분은 생략됩니다.)",
        ],
        isContentNewLine: true,
        warn: null,
        isWarnNewLine: false,
      },
      prPageInfo: {
        title: "소개페이지 유형",
        content: [
          {
            title: "-A형 (준비중) : ",
            content: [
              "소개페이지를 아직 완성하지 못한 경우 홈페이지 주소, 전화번호만 표시.",
              "(이 경우 진료의뢰서 출력 시 회원님의 소개페이지가 출력되지 않습니다.)",
            ],
          },
          {
            title: "-B형 (자동완성) : ",
            content:
              "아래 정보를 입력하면 자동으로 규격에 맞춰 업로드되는 형식.",
          },
          {
            title: "-C형 (디자인 파일) : ",
            content: [
              "회원님이 직접 만든 소개페이지 파일을 업로드하는 형식.",
              "(jpg, png, gif 파일만 업로드 가능)",
              "(가급적이면 이 형태를 사용하실 것을 권장합니다. 디자인 파일이 없을 경우 디자인 제작 의뢰를 맡기실 수 있습니다.)",
            ],
          },
        ],
      },
      form: {
        attachment: [
          {
            isDeleted: null,
            flag: false,
            title: "의사 프로필 사진",
            source: null,
            url: "",
            filename: "",
            warn: "그림파일의 크기는 최소 640*480픽셀을 권장합니다.",
            text: "",
          },
          {
            isDeleted: null,
            flag: false,
            title: "병원 로고",
            source: null,
            url: "",
            filename: "",
            warn: "그림파일의 크기는 최소 640*480픽셀을 권장합니다.",
            text: "",
          },
          {
            isDeleted: null,
            flag: false,
            title: "병원 사진",
            source: null,
            url: "",
            filename: "",
            warn: "그림파일의 크기는 최소 640*480픽셀을 권장합니다.",
            text: "",
          },
          {
            isDeleted: null,
            flag: false,
            title: "병원 약도",
            source: null,
            url: "",
            filename: "",
            warn: "그림파일의 크기는 최소 640*480픽셀을 권장합니다.",
            text: "**병원 약도는 입력한 주소를 기반으로 네이버 지도를 통해 우선 저장 되었습니다.\n\n개별 약도그림으로 교체를 원하시는 경우 '파일첨부'를 통해\n원하시는 이미지파일을 저장해주시길 바랍니다.",
          },
          {
            isDeleted: null,
            flag: false,
            title: "PR Image",
            source: null,
            url: "",
            filename: "",
            warn: "그림파일의 크기는 최소 640*480픽셀을 권장합니다.",
            text: "",
          },
        ],
        tel: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*-와 숫자를 입력해주세요",
        },
        fax: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*-와 숫자를 입력해주세요",
        },
        introduceHospital: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*병원소개를 입력해주세요",
        },
        introduceHospitalDetail: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*병원소개 상세를 입력해주세요",
        },
        introduceDoctor: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*의사소개를 입력해주세요",
        },
        historyDoctor: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*의사약력을 입력해주세요",
        },
        treatmentTime: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "*진료시간을 입력해주세요",
        },
        homepage: {
          type: ["홈페이지"],
          value: "",
          flag: false,
        },
        sampleType: {
          options: ["A형", "B형", "C형"],
          value: "" || "항목 선택",
        },
        templateType: {
          options: ["A형", "B형", "C형"],
          value: "" || "항목 선택",
          flag: false,
          hasError: false,
          errorTxt: "*시안을 선택해주세요",
        },
        subPromotionPageList: {
          options: ["블로그", "페이스북", "트위터", "카페", "기타", "선택안함"],
          item: [
            {
              type: "" || "항목 선택",
              value: "",
              flag: false,
              hasError: false,
              errorTxt: "기타 홈페이지 종류를 선택해주세요",
            },
            {
              type: "" || "항목 선택",
              value: "",
              flag: false,
              hasError: false,
              errorTxt: "기타 홈페이지 종류를 선택해주세요",
            },
            {
              type: "" || "항목 선택",
              value: "",
              flag: false,
              hasError: false,
              errorTxt: "기타 홈페이지 종류를 선택해주세요",
            },
          ],
          isChecked: false,
        },
        resvPage: {
          type: ["예약페이지"],
          value: "",
          flag: false,
        },
        editor: {
          title: "",
          content: "",
        },
      },
      view: {
        pageOrder: null,
        isModifyMode: false,
        btnName: "",
        isEditorMenu: false,
        isFooterVisible: false,
      },
      isDetermined: false,
      setCount: false,
    };
  },
  created() {
    // if (history.pushState !== undefined) {
    //   history.pushState({}, "", location.href);
    // }
    // console.log("########################################");
    // console.log(location.href);
    // console.log("########################################");
    this.getBasicData();
    this.getPromotionData();
    if (this.GET_PASSED_URL !== null) {
      this.checkPastUrl();
    }
  },
  mounted() {},
  computed: {
    ...mapGetters("promotion", ["GET_PROMOTION"]),
    ...mapGetters("util", ["GET_PDF_URL", "GET_PASSED_URL"]),
    ...mapGetters("join", ["GET_BASIC"]),
    ...mapState("basic", ["checkPopup"]),
    computedLine() {
      return (text) => {
        if (text.indexOf("\n") !== -1) {
          return text.split("\n").join("<br />");
        } else {
          return text;
        }
      };
    },
  },
  methods: {
    ...mapActions("promotion", [
      "FETCH_PROMOTION",
      "PROMOTION_SUBMIT",
      "PROMOTION_MODIFY",
    ]),
    ...mapActions("search", ["FETCH_SEARCH"]),
    ...mapActions("join", ["FETCH_BASIC"]),
    ...mapActions("util", [
      "BRING_PDF_PR",
      "BRING_PDF_URL_PR",
      "BRING_FORM_DATA",
    ]),
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapMutations("util", ["SET_PASSED_URL"]),
    focusControl() {
      const focusControl = document.getElementById("focusControl");
      // console.log(focusControl);
      focusControl.focus({ preventScroll: true });
    },
    fiClick(tab) {
      document.getElementById("fi_" + tab).click();
    },
    change_radio(radioValue) {
      // 구버전
      // if (radioValue === '0') {
      //   this.form.templateType.value = "C형";
      //   this.form.sampleType.value = "C형";
      //   this.radioValues = radioValue;
      // } else if (radioValue === '1') {
      //   this.form.templateType.value = "D형";
      //   this.form.sampleType.value = "D형";
      //   this.radioValues = radioValue;
      // } else if (radioValue === '2') {
      //   this.form.templateType.value = "E형";
      //   this.form.sampleType.value = "E형";
      //   this.radioValues = radioValue;
      // } else if (radioValue === '3') {
      //   this.form.templateType.value = "B형";
      //   this.form.sampleType.value = "B형";
      //   this.radioValues = radioValue;
      // } else if (radioValue === '4') {
      //   this.form.templateType.value = "A형";
      //   this.form.sampleType.value = "A형";
      //   this.radioValues = radioValue;
      // }

      //리뉴얼 버전
      if (radioValue === "0") {
        this.form.templateType.value = "B형";
        this.form.sampleType.value = "B형";
        this.radioValues = radioValue;
      } else if (radioValue === "1") {
        this.form.templateType.value = "B형";
        this.form.sampleType.value = "B형";
        this.radioValues = radioValue;
      } else if (radioValue === "2") {
        this.form.templateType.value = "B형";
        this.form.sampleType.value = "B형";
        this.radioValues = radioValue;
      } else if (radioValue === "3") {
        this.form.templateType.value = "C형";
        this.form.sampleType.value = "C형";
        this.radioValues = radioValue;
      } else if (radioValue === "4") {
        this.form.templateType.value = "B형";
        this.form.sampleType.value = "B형";
        this.radioValues = radioValue;
      } else if (radioValue === "5") {
        this.form.templateType.value = "A형";
        this.form.sampleType.value = "A형";
        this.radioValues = radioValue;
      }
    },
    btn_pr_attachment() {
      document.querySelector(".prAttachment").click();
    },
    view_thanq() {
      window.open("http://www.drrefer.net/v/pr/manage/15");
    },
    preview(type) {
      /*this.BRING_FORM_DATA(this.form).then(() => {
        let routeData;
        routeData = this.$router.resolve({
          name: "PrPreview",
          params: {
            form: this.form,
            memberInfo: this.memberInfo,
            view: this.view,
          },
          query: { form: this.form },
        });
        //routeData = this.$router.resolve({name : "PrPreview", params : {form : "a"}});
        window.open(routeData.href, "_blank");
      });*/

      //this.$router.push({name : "PrPreview", params : {form : this.form, memberInfo : this.memberInfo, view :this.view}})
      // console.log(type);

      // if (this.form.sampleType.value === "C형") {
      //   window.open("http://www.drrefer.net/v/pr/manage/10");
      //   return;
      // } else if (this.form.sampleType.value === "D형") {
      //   window.open("http://www.drrefer.net/v/pr/manage/13");
      //   return;
      // } else if (this.form.sampleType.value === "E형") {
      //   window.open("http://www.drrefer.net/v/pr/manage/13");
      //   return;
      // } else if (this.form.sampleType.value === "A형") {
      //   window.open("http://www.drrefer.net/v/pr/manage/14");
      //   return;
      // }

      // if (type !== "null") {
      //   this.form.sampleType.value = type;
      //   let form = JSON.stringify(this.form);
      //   localStorage.setItem("form", form);
      //   let memberInfo = JSON.stringify(this.memberInfo);
      //   localStorage.setItem("memberInfo", memberInfo);
      //   let view = JSON.stringify(this.view);
      //   localStorage.setItem("view", view);
      //   let routeData;
      //   routeData = this.$router.resolve({ name: "PrPreview" });
      //   window.open(routeData.href, "_blank");
      // } else {
      //   // console.log(this.form.sampleType.value);
      //   if (
      //     this.form.sampleType.value === "A형" ||
      //     this.form.sampleType.value === "항목 선택"
      //   ) {
      //     let tmpType = this.form.sampleType.value;
      //     this.form.sampleType.value = "C형";
      //     let form = JSON.stringify(this.form);
      //     localStorage.setItem("form", form);
      //     let memberInfo = JSON.stringify(this.memberInfo);
      //     localStorage.setItem("memberInfo", memberInfo);
      //     let view = JSON.stringify(this.view);
      //     localStorage.setItem("view", view);
      //     let routeData;
      //     routeData = this.$router.resolve({ name: "PrPreview" });
      //     window.open(routeData.href, "_blank");
      //     this.form.sampleType.value = tmpType;
      //   } else {
      //     // console.log("aa");
      //     let form = JSON.stringify(this.form);
      //     localStorage.setItem("form", form);
      //     let memberInfo = JSON.stringify(this.memberInfo);
      //     localStorage.setItem("memberInfo", memberInfo);
      //     let view = JSON.stringify(this.view);
      //     localStorage.setItem("view", view);
      //     let routeData;
      //     routeData = this.$router.resolve({ name: "PrPreview" });
      //     window.open(routeData.href, "_blank");
      //   }
      // }
      console.log(this.form);
      console.log(this.memberInfo);
      console.log(this.view);
      console.log(this.form.sampleType.value);

      let form = JSON.stringify(this.form);
      localStorage.setItem("form", form);
      let memberInfo = JSON.stringify(this.memberInfo);
      localStorage.setItem("memberInfo", memberInfo);
      let view = JSON.stringify(this.view);
      localStorage.setItem("view", view);
      let routeData;
      routeData = this.$router.resolve({ name: "PrPreview" });
      window.open(routeData.href, "_blank");
    },
    async callPopWithSidePassword() {
      let pop;
      pop = {
        popType: "CheckPasswordOwn",
        title: "비밀번호 확인",
        content: "현재 비밀번호를 입력해주세요.",
        immediatly: true,
      };
      this.$refs.appheader.reloadApiPop(pop);
    },
    initAuthInfo() {
      // console.log("앱헤더에서 부를 것");
      this.$refs.appheader.calledNav("withDraw");
    },
    listReaded(order) {
      document.querySelector(".tabs__header").childNodes[order].click();
    },
    phoneFomatter(number) {
      // console.log("정보", number);
      // console.log("정보렝", number.length);
      let formatNum = "";
      if (number.length == 11) {
        formatNum = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
      } else if (number.length == 8) {
        formatNum = number.replace(/(\d{4})(\d{4})/, "$1-$2");
      } else {
        // console.log("핵검증", number.indexOf("02"));
        if (number.indexOf("02") == 0) {
          if (number.length === 9) {
            formatNum = number.replace(/(\d{2})(\d{3})(\d{4})/, "$1-$2-$3");
          } else {
            formatNum = number.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
          }
        } else {
          formatNum = number.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        }
      }
      return formatNum;
    },
    synchronization(editorData) {
      this.form.editor.content = editorData;
    },
    initEditorData() {
      this.form.editor.title = null;
      this.form.editor.content = null;
    },
    checkEditor() {
      // console.log(this.view.isEditorMenu);
      if (this.view.isEditorMenu === true) {
        this.initEditorData();
      }
    },
    // handleGoback() {
    //   if (this.setCount) {
    //     this.$router.push("/v/request/list").catch(()=>{});
    //   } else {
    //     history.go(1);
    //   }
    //   this.setCount = true;
    //   // 클로즈 함수 구현
    // },
    async checkPrData() {
      try {
        await this.FETCH_SEARCH().then(() => {
          if (localStorage.getItem("popYn") !== null) {
            localStorage.setItem("popYn", "Y");
          }
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
    checkPastUrl() {
      if (this.GET_PASSED_URL === "manage") {
        this.isDetermined = true;
      }
    },
    download(order) {
      if (order === 0) {
        if (
          this.GET_PROMOTION.drPicAttchNm === this.form.attachment[0].filename
        ) {
          window.open(
            "https://www.drrefer.net/api/pic/" +
              this.GET_PROMOTION.drPicAttchId,
            "_blank"
          );
        }
      } else if (order === 1) {
        if (
          this.GET_PROMOTION.logoAttchNm === this.form.attachment[1].filename
        ) {
          window.open(
            "https://www.drrefer.net/api/pic/" + this.GET_PROMOTION.logoAttchId,
            "_blank"
          );
        }
      } else if (order === 2) {
        if (
          this.GET_PROMOTION.picAttchNm === this.form.attachment[2].filename
        ) {
          window.open(
            "https://www.drrefer.net/api/pic/" + this.GET_PROMOTION.picAttchId,
            "_blank"
          );
        }
      } else if (order === 3) {
        if (
          this.GET_PROMOTION.mapAttchNm === this.form.attachment[3].filename
        ) {
          window.open(
            "https://www.drrefer.net/api/pic/" + this.GET_PROMOTION.mapAttchId,
            "_blank"
          );
        }
      }
    },
    async hyperPdf() {
      await this.callPdf();
      await this.fileCall();
    },
    async fileCall() {
      try {
        await this.BRING_PDF_PR(this.GET_PDF_URL.fileName).then(() => {
          const blob = new Blob([this.GET_PDF_URL], {
            type: "application/pdf",
          });
          const result = URL.createObjectURL(blob);
          // console.log("찾기", result);
          const url = "https://www.drrefer.net/pdf/" + result;
          // console.log("called_Pdf_url", url);

          window.open(result, "_blank");
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
    async callPdf() {
      // console.log("넘길인자", this.memberInfo.drId);
      try {
        await this.BRING_PDF_URL_PR(this.memberInfo.drId).then(() => {});
      } catch (error) {
        console.log("error :", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = "PDF를 불러올 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    async getBasicData() {
      try {
        await this.FETCH_BASIC().then(() => {
          // console.log("찾기 회원정보", this.GET_BASIC);
          if (this.GET_BASIC.hospNm !== null) {
            this.memberInfo.hospNm = this.GET_BASIC.hospNm;
          }
          if (this.GET_BASIC.drNm !== null) {
            this.memberInfo.drNm = this.GET_BASIC.drNm;
          }
          if (this.GET_BASIC.drId !== null) {
            this.memberInfo.drId = this.GET_BASIC.drId;
          }
          if (this.GET_BASIC.addr !== null) {
            this.memberInfo.addr = this.GET_BASIC.addr;
          }
          if (this.GET_BASIC.addrDtl !== null) {
            this.memberInfo.addrDtl = this.GET_BASIC.addrDtl;
          }
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
    checkTreatmentTime(event, isSecond) {
      const target = event.target;
      if (this.view.pageOrder !== null) {
        if (this.view.pageOrder !== 1) {
          target.style.height = "26px";
        } else {
          target.style.height = "26px";
        }
        target.style.height = target.scrollHeight + "px";
      }
      const lines = this.form.treatmentTime.value.match(/\n/g);
      const separatedLines = this.form.treatmentTime.value.split("\n");
      let restrictStr;
      if (isSecond !== undefined) {
        if (lines !== null) {
          if (lines.length === 1) {
            /** 길이가 1이상이면 */
            restrictStr = separatedLines[0] + "\n" + separatedLines[1];
          } else {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2];
            "\n" + separatedLines[3];
          }
          // console.log("현재 어떻게!!?", lines.length);
          this.form.treatmentTime.value = restrictStr;
        }
      } else {
        if (lines !== null) {
          if (lines.length === 1) {
            // console.log("1보다 큰 진입");
            /** 길이가 1이상이면 */
            restrictStr = separatedLines[0] + "\n" + separatedLines[1];
          } else if (lines.length === 2) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2];
          } else if (lines.length === 3) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3];
          } else if (lines.length === 4) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3] +
              "\n" +
              separatedLines[4];
          } else if (lines.length === 5) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3] +
              "\n" +
              separatedLines[4] +
              "\n" +
              separatedLines[5];
          } else if (lines.length === 6) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3] +
              "\n" +
              separatedLines[4] +
              "\n" +
              separatedLines[5] +
              "\n" +
              separatedLines[6];
          } else if (lines.length === 7) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3] +
              "\n" +
              separatedLines[4] +
              "\n" +
              separatedLines[5] +
              "\n" +
              separatedLines[6] +
              "\n" +
              separatedLines[7];
          } else {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3] +
              "\n" +
              separatedLines[4] +
              "\n" +
              separatedLines[5] +
              "\n" +
              separatedLines[6] +
              "\n" +
              separatedLines[7] +
              "\n" +
              separatedLines[8];
          }
          // console.log("현재 어떻게?", lines.length);
          this.form.treatmentTime.value = restrictStr;
        }
      }
      if (this.form.treatmentTime.value !== null) {
        this.form.treatmentTime.flag = false;
        this.form.treatmentTime.hasError = true;
        const TreatmentTime = this.form.treatmentTime.value.replace(/\s/gi, "");

        if (TreatmentTime === "") {
          this.form.treatmentTime.flag = false;
          this.form.treatmentTime.hasError = true;
          this.form.treatmentTime.errorTxt = "*진료시간을 입력해주세요.";
          return false;
        }
        if (TreatmentTime.length < 5) {
          this.form.treatmentTime.flag = false;
          this.form.treatmentTime.hasError = true;
          this.form.treatmentTime.errorTxt =
            "*진료시간을 5자 이상 입력해주세요.";
          return false;
        }
        if (TreatmentTime.length > 100) {
          this.form.treatmentTime.flag = false;
          this.form.treatmentTime.hasError = true;
          this.form.treatmentTime.errorTxt =
            "*진료시간을 60자 이하로 입력해주세요.";
          const temper = this.form.treatmentTime.value.substring(0, 100);
          this.form.treatmentTime.value = temper;
          event.target.value.substring(0, 100);
          return false;
        }
        const isTreatmentTime =
          /^[ㄱ-ㅎ가-힣|a-z|A-Z|0-9|.|,|-|"|:|~|'|]{5,100}$/;
        if (!isTreatmentTime.test(TreatmentTime)) {
          this.form.treatmentTime.errorTxt =
            "*특수문자를 제외하고 100자 내로 입력이 가능합니다.";
          this.form.treatmentTime.flag = false;
          this.form.treatmentTime.hasError = true;
          return false;
        }
        // console.log("성공렝", TreatmentTime.length);
        this.form.treatmentTime.flag = true;
        this.form.treatmentTime.hasError = false;
        this.form.treatmentTime.errorTxt = "";
        return true;
      }
    },
    checkEtcPage(order) {
      if (this.form.subPromotionPageList.item[order].value !== null) {
        this.form.subPromotionPageList.item[order].flag = false;
        this.form.subPromotionPageList.item[order].hasError = true;
        const etcUrl = this.form.subPromotionPageList.item[order].value.replace(
          /\s/gi,
          ""
        );
        if (etcUrl === "") {
          this.form.subPromotionPageList.item[order].flag = false;
          this.form.subPromotionPageList.item[order].hasError = true;
          this.form.subPromotionPageList.item[order].errorTxt =
            "*홈페이지 주소를 입력해주세요.";
          return false;
        }
        if (etcUrl.length < 5) {
          this.form.subPromotionPageList.item[order].flag = false;
          this.form.subPromotionPageList.item[order].hasError = true;
          this.form.subPromotionPageList.item[order].errorTxt =
            "*홈페이지 주소를 5자이상 입력해주세요.";
          return false;
        }
        const isEtcUrl = /^[ㄱ-ㅎ가-힣|a-z|A-Z|0-9|.|-|]{5,200}$/;
        if (!isEtcUrl.test(etcUrl)) {
          this.form.subPromotionPageList.item[order].errorTxt =
            "*특수문자를 제외하고 50자 내로 입력이 가능합니다.";
          this.form.subPromotionPageList.item[order].flag = false;
          this.form.subPromotionPageList.item[order].hasError = true;
          return false;
        }
        this.form.subPromotionPageList.item[order].flag = true;
        this.form.subPromotionPageList.item[order].hasError = false;
        return true;
      }
    },
    selectInputClick(order) {
      if (this.form.subPromotionPageList.item[order].type === "항목 선택") {
        this.$refs.etc[order].$el.parentElement.classList.add("caution");
        this.form.subPromotionPageList.item[order].hasError = true;
      }
    },
    isEtc(order) {
      if (this.form.subPromotionPageList.item[order].type !== "선택안함") {
        this.form.subPromotionPageList.item[order].hasError = true;
      } else {
        this.form.subPromotionPageList.item[order].hasError = false;
      }
      this.form.subPromotionPageList.item[order].flag = false;
      this.form.subPromotionPageList.item[order].value = "";
    },
    checkHomepage() {
      if (this.form.homepage.value !== null) {
        this.form.homepage.flag = false;
        const Homepage = this.form.homepage.value.replace(/\s/gi, "");
        if (Homepage === "") {
          this.form.homepage.flag = false;
          return false;
        }
        this.form.homepage.flag = true;
        return true;
      }
    },
    checkFax() {
      if (this.form.fax.value !== null) {
        this.form.fax.flag = false;
        this.form.fax.hasError = true;
        const fax = this.form.fax.value.replace(/\s/gi, "");
        if (fax === "") {
          this.form.fax.flag = false;
          this.form.fax.hasError = true;
          this.form.fax.errorTxt = "*팩스번호를 입력해주세요.";
          return false;
        }
        const isFax = /^[0-9|-]{10,13}$/;
        if (!isFax.test(fax)) {
          this.form.fax.errorTxt = "*-와 숫자만 입력 가능합니다.";
          this.form.fax.flag = false;
          this.form.fax.hasError = true;
          return false;
        }
        this.form.fax.flag = true;
        this.form.fax.hasError = false;
        this.form.fax.errorTxt = "";
        return true;
      }
    },
    checkTel() {
      if (this.form.tel.value !== null) {
        this.form.tel.flag = false;
        this.form.tel.hasError = true;
        const tel = this.form.tel.value.replace(/\s/gi, "");
        if (tel === "") {
          this.form.tel.flag = false;
          this.form.tel.hasError = true;
          this.form.tel.errorTxt = "*전화번호를 입력해주세요.";
          return false;
        }
        const isTel = /^[0-9|-]{10,13}$/;
        if (!isTel.test(tel)) {
          this.form.tel.errorTxt = "*-와 숫자만 입력 가능합니다.";
          this.form.tel.flag = false;
          this.form.tel.hasError = true;
          return false;
        }
        this.form.tel.flag = true;
        this.form.tel.hasError = false;
        this.form.tel.errorTxt = "";
        return true;
      }
    },
    checkIntroduceDoctor(event, isSecond) {
      const target = event.target;
      if (this.view.pageOrder !== null) {
        if (this.view.pageOrder !== 1) {
          target.style.height = "26px";
        } else {
          target.style.height = "26px";
        }
        target.style.height = target.scrollHeight + "px";
      }
      const lines = this.form.introduceDoctor.value.match(/\n/g);
      const separatedLines = this.form.introduceDoctor.value.split("\n");
      let restrictStr;
      if (isSecond !== undefined) {
        if (lines !== null) {
          // console.log("현재의 라인", lines.length);
          if (lines.length === 1) {
            restrictStr = separatedLines[0] + "\n" + separatedLines[1];
          } else if (lines.length === 2) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2];
          } else if (lines.length === 3) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3];
          } else if (lines.length === 4) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3] +
              "\n" +
              separatedLines[4];
          } else if (lines.length === 5) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3] +
              "\n" +
              separatedLines[4] +
              "\n" +
              separatedLines[5];
          } else if (lines.length === 6) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3] +
              "\n" +
              separatedLines[4] +
              "\n" +
              separatedLines[5] +
              "\n" +
              separatedLines[6];
          } else if (lines.length === 7) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3] +
              "\n" +
              separatedLines[4] +
              "\n" +
              separatedLines[5] +
              "\n" +
              separatedLines[6] +
              "\n" +
              separatedLines[7];
          } else {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3] +
              "\n" +
              separatedLines[4] +
              "\n" +
              separatedLines[5] +
              "\n" +
              separatedLines[6] +
              "\n" +
              separatedLines[7] +
              "\n" +
              separatedLines[8];
          }
          this.form.introduceDoctor.value = restrictStr;
        }
      } else {
        if (lines !== null) {
          // console.log("현재의 라인", lines.length);
          if (lines.length === 1) {
            restrictStr = separatedLines[0] + "\n" + separatedLines[1];
          } else if (lines.length === 2) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2];
          } else if (lines.length === 3) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3];
          } else if (lines.length === 4) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3] +
              "\n" +
              separatedLines[4];
          } else if (lines.length === 5) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3] +
              "\n" +
              separatedLines[4] +
              "\n" +
              separatedLines[5];
          } else {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3] +
              "\n" +
              separatedLines[4] +
              "\n" +
              separatedLines[5] +
              "\n" +
              separatedLines[6];
          }
          this.form.introduceDoctor.value = restrictStr;
        }
      }
      if (this.form.introduceDoctor.value !== null) {
        this.form.introduceDoctor.flag = false;
        this.form.introduceDoctor.hasError = true;
        const IntroduceDoctor = this.form.introduceDoctor.value.replace(
          /\s/gi,
          ""
        );

        if (IntroduceDoctor === "") {
          this.form.introduceDoctor.flag = false;
          this.form.introduceDoctor.hasError = true;
          this.form.introduceDoctor.errorTxt = "*의사소개를 입력해주세요.";
          return false;
        }
        if (IntroduceDoctor.length < 5) {
          this.form.introduceDoctor.flag = false;
          this.form.introduceDoctor.hasError = true;
          this.form.introduceDoctor.errorTxt =
            "*의사소개를 5자 이상 입력해주세요.";
          return false;
        }
        if (IntroduceDoctor.length > 200) {
          this.form.introduceDoctor.flag = false;
          this.form.introduceDoctor.hasError = true;
          this.form.introduceDoctor.errorTxt =
            "*의사소개를 50자 이하로 입력해주세요.";
          const temper = this.form.introduceDoctor.value.substring(0, 200);
          this.form.introduceDoctor.value = temper;
          event.target.value.substring(0, 200);
          return false;
        }
        const isIntroduceDoctor =
          /^[ㄱ-ㅎ가-힣|a-z|A-Z|0-9|.|,|-|"|:|~|'|]{5,200}$/;
        if (!isIntroduceDoctor.test(IntroduceDoctor)) {
          this.form.introduceDoctor.errorTxt =
            "*특수문자를 제외하고 200자 내로 입력이 가능합니다.";
          this.form.introduceDoctor.flag = false;
          this.form.introduceDoctor.hasError = true;
          return false;
        }
        // console.log("현재길이는?", IntroduceDoctor.length);
        this.form.introduceDoctor.flag = true;
        this.form.introduceDoctor.hasError = false;
        this.form.introduceDoctor.errorTxt = "";
        return true;
      }
    },
    checkHistoryDoctor(event, isSecond) {
      const target = event.target;
      if (this.view.pageOrder !== null) {
        if (this.view.pageOrder !== 1) {
          target.style.height = "26px";
        } else {
          target.style.height = "26px";
        }
        target.style.height = target.scrollHeight + "px";
      }
      const lines = this.form.historyDoctor.value.match(/\n/g);
      const separatedLines = this.form.historyDoctor.value.split("\n");
      let restrictStr;
      if (isSecond !== undefined) {
        if (lines !== null) {
          if (lines.length === 1) {
            restrictStr = separatedLines[0] + "\n" + separatedLines[1];
          } else if (lines.length === 2) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2];
          } else {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3];
          }
          this.form.historyDoctor.value = restrictStr;
        }
      } else {
        if (lines !== null) {
          if (lines.length === 1) {
            restrictStr = separatedLines[0] + "\n" + separatedLines[1];
          } else {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2];
          }
          this.form.historyDoctor.value = restrictStr;
        }
      }

      if (this.form.historyDoctor.value !== null) {
        this.form.historyDoctor.flag = false;
        this.form.historyDoctor.hasError = true;
        const HistoryDoctor = this.form.historyDoctor.value.replace(/\s/gi, "");

        if (HistoryDoctor === "") {
          this.form.historyDoctor.flag = false;
          this.form.historyDoctor.hasError = true;
          this.form.historyDoctor.errorTxt = "*의사약력을 입력해주세요.";
          return false;
        }
        if (HistoryDoctor.length < 5) {
          this.form.historyDoctor.flag = false;
          this.form.historyDoctor.hasError = true;
          this.form.historyDoctor.errorTxt =
            "*의사약력을 5자 이상 입력해주세요.";
          return false;
        }
        if (HistoryDoctor.length > 87) {
          this.form.historyDoctor.flag = false;
          this.form.historyDoctor.hasError = true;
          this.form.historyDoctor.errorTxt =
            "*의사약력을 87자 이하로 입력해주세요.";
          const temper = this.form.historyDoctor.value.substring(0, 87);
          this.form.historyDoctor.value = temper;
          event.target.value.substring(0, 87);
          return false;
        }
        const isHistoryDoctor =
          /^[ㄱ-ㅎ가-힣|a-z|A-Z|0-9|.|,|-|"|:|~|'|]{5,87}$/;
        if (!isHistoryDoctor.test(HistoryDoctor)) {
          this.form.historyDoctor.errorTxt =
            "*특수문자를 제외하고 87자 내로 입력이 가능합니다.";
          this.form.historyDoctor.flag = false;
          this.form.historyDoctor.hasError = true;
          return false;
        }
        // console.log("현재길이는", HistoryDoctor.length);
        this.form.historyDoctor.flag = true;
        this.form.historyDoctor.hasError = false;
        this.form.historyDoctor.errorTxt = "";
        return true;
      }
    },
    checkIntroduceHospitalDetail(event, isSecond) {
      const target = event.target;
      if (this.view.pageOrder !== null) {
        if (this.view.pageOrder !== 1) {
          target.style.height = "26px";
        } else {
          target.style.height = "26px";
        }
        target.style.height = target.scrollHeight + "px";
      }
      const lines = this.form.introduceHospitalDetail.value.match(/\n/g);
      const separatedLines =
        this.form.introduceHospitalDetail.value.split("\n");
      let restrictStr;
      if (isSecond !== undefined) {
        if (lines !== null) {
          if (lines.length === 1) {
            restrictStr = separatedLines[0] + "\n" + separatedLines[1];
          } else if (lines.length === 2) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2];
          } else {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3];
          }
          this.form.introduceHospitalDetail.value = restrictStr;
        }
      } else {
        if (lines !== null) {
          if (lines.length === 1) {
            restrictStr = separatedLines[0] + "\n" + separatedLines[1];
          } else if (lines.length === 2) {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2];
          } else {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2] +
              "\n" +
              separatedLines[3];
          }
          this.form.introduceHospitalDetail.value = restrictStr;
        }
      }

      if (this.form.introduceHospitalDetail.value !== null) {
        this.form.introduceHospitalDetail.flag = false;
        this.form.introduceHospitalDetail.hasError = true;
        const IntroduceHospitalDetail =
          this.form.introduceHospitalDetail.value.replace(/\s/gi, "");

        if (IntroduceHospitalDetail === "") {
          this.form.introduceHospitalDetail.flag = false;
          this.form.introduceHospitalDetail.hasError = true;
          this.form.introduceHospitalDetail.errorTxt =
            "*병원 상세내용을 입력해주세요.";
          return false;
        }
        if (IntroduceHospitalDetail.length < 5) {
          this.form.introduceHospitalDetail.flag = false;
          this.form.introduceHospitalDetail.hasError = true;
          this.form.introduceHospitalDetail.errorTxt =
            "*병원 상세내용을 5자 이상 입력해주세요.";
          return false;
        }
        if (IntroduceHospitalDetail.length > 200) {
          this.form.introduceHospitalDetail.flag = false;
          this.form.introduceHospitalDetail.hasError = true;
          this.form.introduceHospitalDetail.errorTxt =
            "*병원 상세내용을 200자 이하로 입력해주세요.";
          const temper = this.form.introduceHospitalDetail.value.substring(
            0,
            200
          );
          this.form.introduceHospitalDetail.value = temper;
          event.target.value.substring(0, 200);
          return false;
        }
        const isIntroduceHospitalDetail =
          /^[ㄱ-ㅎ가-힣|a-z|A-Z|0-9|.|,|-|"|:|~|'|]{5,200}$/;
        if (!isIntroduceHospitalDetail.test(IntroduceHospitalDetail)) {
          this.form.introduceHospitalDetail.errorTxt =
            "*특수문자를 제외하고 200자 내로 입력이 가능합니다.";
          this.form.introduceHospitalDetail.flag = false;
          this.form.introduceHospitalDetail.hasError = true;
          return false;
        }
        // console.log("현재의 길이는", IntroduceHospitalDetail.length);
        this.form.introduceHospitalDetail.flag = true;
        this.form.introduceHospitalDetail.hasError = false;
        this.form.introduceHospitalDetail.errorTxt = "";
        return true;
      }
    },
    checkIntroduceHospital(event, isSecond) {
      const target = event.target;
      if (this.view.pageOrder !== null) {
        if (this.view.pageOrder !== 1) {
          target.style.height = "26px";
        } else {
          target.style.height = "26px";
        }
        target.style.height = target.scrollHeight + "px";
      }
      const lines = this.form.introduceHospital.value.match(/\n/g);
      const separatedLines = this.form.introduceHospital.value.split("\n");
      let restrictStr;
      if (isSecond !== undefined) {
        if (lines !== null) {
          restrictStr = separatedLines[0] + "\n" + separatedLines[1];
          this.form.introduceHospital.value = restrictStr;
        }
      } else {
        if (lines !== null) {
          if (lines.length === 1) {
            /** 길이가 1이상이면 */
            restrictStr = separatedLines[0] + "\n" + separatedLines[1];
          } else {
            restrictStr =
              separatedLines[0] +
              "\n" +
              separatedLines[1] +
              "\n" +
              separatedLines[2];
          }
          this.form.introduceHospital.value = restrictStr;
        }
      }

      if (this.form.introduceHospital.value !== null) {
        this.form.introduceHospital.flag = false;
        this.form.introduceHospital.hasError = true;
        const IntroduceHospital = this.form.introduceHospital.value.replace(
          /\s/gi,
          ""
        );

        if (IntroduceHospital === "") {
          this.form.introduceHospital.flag = false;
          this.form.introduceHospital.hasError = true;
          this.form.introduceHospital.errorTxt = "*병원소개를 입력해주세요.";
          return false;
        }
        if (IntroduceHospital.length < 5) {
          this.form.introduceHospital.flag = false;
          this.form.introduceHospital.hasError = true;
          this.form.introduceHospital.errorTxt =
            "*병원소개를 5자 이상 입력해주세요.";
          return false;
        }
        if (IntroduceHospital.length > 100) {
          this.form.introduceHospital.flag = false;
          this.form.introduceHospital.hasError = true;
          this.form.introduceHospital.errorTxt =
            "*병원소개를 100자 이하로 입력해주세요.";
          const temper = this.form.introduceHospital.value.substring(0, 100);
          // console.log("temper", temper);
          this.form.introduceHospital.value = temper;
          event.target.value.substring(0, 10);
          return false;
        }
        const isIntroduceHospital =
          /^[ㄱ-ㅎ가-힣|a-z|A-Z|0-9|.|,|-|"|:|~|'|]{5,100}$/;
        if (!isIntroduceHospital.test(IntroduceHospital)) {
          this.form.introduceHospital.errorTxt =
            "*특수문자를 제외하고 100자 내로 입력이 가능합니다.";
          this.form.introduceHospital.flag = false;
          this.form.introduceHospital.hasError = true;
          return false;
        }
        // console.log("현재의 길이는", IntroduceHospital.length);
        this.form.introduceHospital.flag = true;
        this.form.introduceHospital.hasError = false;
        this.form.introduceHospital.errorTxt = "";
        return true;
      }
    },
    movePaymentDesign() {
      this.$router.push("/v/payment/designList").catch(() => {});
    },
    isTemplate(order) {
      /** this.$refs.templateType.$el.parentElement.classList.remove("caution"); */
      this.form.templateType.hasError = false;
      this.form.templateType.flag = true;
      this.form.sampleType.value = order;
      this.change_radio(order);
    },
    isSampleView() {
      // console.log("sein : " + this.form.sampleType.value);
      if (this.form.sampleType.value === "C형") {
        // console.log("C형시안을 보았음");
        window.open("http://www.drrefer.net/v/pr/manage/10");
        //this.loadRef(1, 10);
      } else if (this.form.sampleType.value === "D형") {
        // console.log("D형을 보았음");
        window.open("http://www.drrefer.net/v/pr/manage/13");
        //this.loadRef(1, 11);
      } else if (this.form.sampleType.value === "E형") {
        window.open("http://www.drrefer.net/v/pr/manage/13");
        // console.log("E형을 보았음");
        //this.loadRef(1, 12);
      } else if (this.form.sampleType.value === "B형") {
        window.open("http://www.drrefer.net/v/pr/manage/13");
        // console.log("B형을 보았음");
      } else if (this.form.sampleType.value === "A형") {
        window.open("http://www.drrefer.net/v/pr/manage/14");
        // console.log("A형을 보았음");
      }
      //window.scrollTo(0, 0);
    },
    isSampleView_thanq() {
      window.open("http://www.drrefer.net/v/pr/manage/15");
      // console.log("A형을 보았음");
    },
    setSelect(type, value) {
      // console.log("진입");
      if (type === "templateType") {
        //console.log("type", type);
        //console.log("value", value);
        if (value === "0") {
          this.form.templateType.value = "B형";
          this.form.sampleType.value = "B형";
        } else if (value === "1") {
          this.form.templateType.value = "B형";
          this.form.sampleType.value = "B형";
        } else if (value === "2") {
          this.form.templateType.value = "B형";
          this.form.sampleType.value = "B형";
        } else if (value === "3") {
          this.form.templateType.value = "C형";
          //this.form.sampleType.value = "시안D";
        } else if (value === "4") {
          this.form.templateType.value = "B형";
          //this.form.sampleType.value = "제작중";
        } else if (value === "5") {
          this.form.templateType.value = "A형";
          //this.form.sampleType.value = "제작중";
        }
        if (value !== null) {
          this.form.templateType.flag = true;
        }
      }
    },
    prePareTextareaTypeB() {
      const wholeTextarea = document.querySelectorAll(".textarea-wrap");
      let target;
      let str;
      let transedStr;
      let mutatedStr;
      for (let i = 0; i < wholeTextarea.length; i++) {
        if (i === 0) {
          str = this.form.historyDoctor.value;
          target = wholeTextarea[i].querySelector("textarea");
          if (this.view.pageOrder !== null) {
            target.style.height = "26px";
            target.style.height = target.scrollHeight + "px";
          }
          if (str !== null) {
            transedStr = str.replace(/(?:\r\n|\r|\n)/g, "<br/>");
            mutatedStr = transedStr.replaceAll("<br/>", "\r\n");
            target.value = mutatedStr;
          }
        } else if (i === 1) {
          str = this.form.introduceDoctor.value;
          target = wholeTextarea[i].querySelector("textarea");
          if (this.view.pageOrder !== null) {
            target.style.height = "26px";
            target.style.height = target.scrollHeight + "px";
          }
          if (str !== null) {
            transedStr = str.replace(/(?:\r\n|\r|\n)/g, "<br/>");
            mutatedStr = transedStr.replaceAll("<br/>", "\r\n");
            target.value = mutatedStr;
          }
        } else if (i === 2) {
          str = this.form.introduceHospital.value;
          target = wholeTextarea[i].querySelector("textarea");
          if (this.view.pageOrder !== null) {
            target.style.height = "26px";
            target.style.height = target.scrollHeight + "px";
          }
          if (str !== null) {
            transedStr = str.replace(/(?:\r\n|\r|\n)/g, "<br/>");
            mutatedStr = transedStr.replaceAll("<br/>", "\r\n");
            target.value = mutatedStr;
          }
        } else if (i === 3) {
          str = this.form.introduceHospitalDetail.value;
          target = wholeTextarea[i].querySelector("textarea");
          if (this.view.pageOrder !== null) {
            target.style.height = "26px";
            target.style.height = target.scrollHeight + "px";
          }
          if (str !== null) {
            transedStr = str.replace(/(?:\r\n|\r|\n)/g, "<br/>");
            mutatedStr = transedStr.replaceAll("<br/>", "\r\n");
            target.value = mutatedStr;
          }
        } else if (i === 4) {
          str = this.form.treatmentTime.value;
          target = wholeTextarea[i].querySelector("textarea");
          if (this.view.pageOrder !== null) {
            target.style.height = "26px";
            target.style.height = target.scrollHeight + "px";
          }
          if (str !== null) {
            transedStr = str.replace(/(?:\r\n|\r|\n)/g, "<br/>");
            mutatedStr = transedStr.replaceAll("<br/>", "\r\n");
            target.value = mutatedStr;
          }
        }
      }
    },
    prePareTextarea() {
      const wholeTextarea = document.querySelectorAll(".textarea-wrap");
      let target;
      let str;
      let transedStr;
      let mutatedStr;
      for (let i = 0; i < wholeTextarea.length; i++) {
        if (i === 0) {
          str = this.form.introduceHospital.value;
          target = wholeTextarea[i].querySelector("textarea");
          if (this.view.pageOrder !== null) {
            target.style.height = "26px";
            target.style.height = target.scrollHeight + "px";
          }
          if (str !== null) {
            transedStr = str.replace(/(?:\r\n|\r|\n)/g, "<br/>");
            mutatedStr = transedStr.replaceAll("<br/>", "\r\n");
            target.value = mutatedStr;
          }
        } else if (i === 1) {
          str = this.form.introduceHospitalDetail.value;
          target = wholeTextarea[i].querySelector("textarea");
          if (this.view.pageOrder !== null) {
            target.style.height = "26px";
            target.style.height = target.scrollHeight + "px";
          }
          if (str !== null) {
            transedStr = str.replace(/(?:\r\n|\r|\n)/g, "<br/>");
            mutatedStr = transedStr.replaceAll("<br/>", "\r\n");
            target.value = mutatedStr;
          }
        } else if (i === 2) {
          str = this.form.historyDoctor.value;

          target = wholeTextarea[i].querySelector("textarea");
          if (this.view.pageOrder !== null) {
            target.style.height = "26px";
            target.style.height = target.scrollHeight + "px";
          }
          if (str !== null) {
            transedStr = str.replace(/(?:\r\n|\r|\n)/g, "<br/>");
            mutatedStr = transedStr.replaceAll("<br/>", "\r\n");
            target.value = mutatedStr;
          }
        } else if (i === 3) {
          str = this.form.introduceDoctor.value;
          target = wholeTextarea[i].querySelector("textarea");
          if (this.view.pageOrder !== null) {
            target.style.height = "26px";
            target.style.height = target.scrollHeight + "px";
          }
          if (str !== null) {
            transedStr = str.replace(/(?:\r\n|\r|\n)/g, "<br/>");
            mutatedStr = transedStr.replaceAll("<br/>", "\r\n");
            target.value = mutatedStr;
          }
        } else if (i === 4) {
          str = this.form.treatmentTime.value;
          target = wholeTextarea[i].querySelector("textarea");
          if (this.view.pageOrder !== null) {
            target.style.height = "26px";
            target.style.height = target.scrollHeight + "px";
          }
          if (str !== null) {
            transedStr = str.replace(/(?:\r\n|\r|\n)/g, "<br/>");
            mutatedStr = transedStr.replaceAll("<br/>", "\r\n");
            target.value = mutatedStr;
          }
        }
      }
      /** str.replace(/(?:\r\n|\r|\n)/g, "<br/>"); */
      /** str.replaceAll("<br/>", "\r\n"); */
    },
    checkPrHospIntro() {
      const IntroduceHospital = this.form.introduceHospital.value.replace(
        /\s/gi,
        ""
      );
      // console.log("hos체크", IntroduceHospital.length);
      if (IntroduceHospital.length > 90) {
        this.form.introduceHospital.flag = false;
        this.form.introduceHospital.hasError = true;
        this.form.introduceHospital.errorTxt = "글자수가 초과되었습니다.";
      }
      this.form.introduceHospital.flag = true;
      this.form.introduceHospital.hasError = false;
      return true;
    },
    prohibition(type) {
      if (type === "hospNm") {
        this.popupSet.title = "병원명 변경불가";
        this.popupSet.content =
          "'병원명' 은 기본정보 수정에서 변경할 수 있습니다.";
      } else if (type === "drNm") {
        this.popupSet.title = "병원명, 의사이름 변경불가";
        this.popupSet.content =
          "'병원명 및 의사이름' 은 기본정보 수정에서 변경할 수 있습니다.";
      } else if (type == "address") {
        this.popupSet.title = "주소 변경불가";
        this.popupSet.content =
          "'주소' 는 기본정보 수정에서 변경할 수 있습니다.";
      }
      this.SET_POPUP(true);

      this.popupSet.popType = "warn";
      this.popupSet.cancelBtnText = "확인";
    },
    prev() {
      // console.log(this.$refs.tabs.selectedIndex);
      // if (this.$refs.tabs.selectedIndex === 0) {
      //   this.loadRef(0);
      //   window.scroll(0, 0);
      // } else {
      //   window.history.go(-1);
      // }
      this.$router.go(-1);
    },

    deletePicture(order) {
      this.form.attachment[order].isDeleted = 1;
      this.form.attachment[order].flag = false;
      this.form.attachment[order].source = null;
      this.form.attachment[order].url = "";
      this.form.attachment[order].filename = "";
    },
    async getPromotionData() {
      /**
       *  삭제,변경 => 1
       *  유지,입력 => 0
       *
       */
      try {
        await this.FETCH_PROMOTION().then(() => {
          if (
            this.GET_PROMOTION.hospIntroType !== null &&
            this.GET_PROMOTION.hospIntroType !== ""
          ) {
            if (this.GET_PROMOTION.hospIntroType === "0") {
              this.form.sampleType.value = "B형";
              this.radioValues = "4";
            } else if (this.GET_PROMOTION.hospIntroType === "1") {
              this.form.sampleType.value = "B형";
              this.radioValues = "4";
            } else if (this.GET_PROMOTION.hospIntroType === "2") {
              this.form.sampleType.value = "B형";
              this.radioValues = "4";
            } else if (this.GET_PROMOTION.hospIntroType === "3") {
              this.form.sampleType.value = "C형";
              this.radioValues = "3";
            } else if (this.GET_PROMOTION.hospIntroType === "4") {
              this.form.sampleType.value = "B형";
              this.radioValues = "4";
            } else if (this.GET_PROMOTION.hospIntroType === "5") {
              this.form.sampleType.value = "A형";
              this.radioValues = "5";
            }
          }
          this.form.tel.value =
            this.GET_PROMOTION.hospTel === null
              ? ""
              : this.phoneFomatter(this.GET_PROMOTION.hospTel);
          // console.log(this.form.tel.value);
          this.form.tel.flag =
            this.GET_PROMOTION.hospTel !== null ? true : false;
          if (this.GET_PROMOTION.doctIntro !== null) {
            this.view.isModifyMode = true;
            this.view.btnName = "정보저장";
            this.loadRef(0);
            if (
              this.GET_PROMOTION.drPicAttchId !== null &&
              this.GET_PROMOTION.drPicAttchId !== ""
            ) {
              this.form.attachment[0].url =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.drPicAttchId;
              this.form.attachment[0].filename =
                this.GET_PROMOTION.drPicAttchNm;
              this.imageMap[0].value = this.GET_PROMOTION.drPicAttchNm;
            }
            if (
              this.GET_PROMOTION.logoAttchId !== null &&
              this.GET_PROMOTION.logoAttchId !== ""
            ) {
              this.form.attachment[1].url =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.logoAttchId;
              this.form.attachment[1].filename = this.GET_PROMOTION.logoAttchNm;
              this.imageMap[1].value = this.GET_PROMOTION.logoAttchNm;
            }
            if (
              this.GET_PROMOTION.picAttchId !== null &&
              this.GET_PROMOTION.picAttchId !== ""
            ) {
              this.form.attachment[2].url =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.picAttchId;
              this.form.attachment[2].filename = this.GET_PROMOTION.picAttchNm;
              this.imageMap[2].value = this.GET_PROMOTION.picAttchNm;
            }
            if (
              this.GET_PROMOTION.mapAttchId !== null &&
              this.GET_PROMOTION.mapAttchId !== ""
            ) {
              this.form.attachment[3].url =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.mapAttchId;
              this.form.attachment[3].filename = this.GET_PROMOTION.mapAttchNm;
              this.imageMap[3].value = this.GET_PROMOTION.mapAttchNm;
            }
            if (
              this.GET_PROMOTION.prAttchId !== null &&
              this.GET_PROMOTION.prAttchId !== ""
            ) {
              // console.log("qweqweqew0");
              // console.log(this.GET_PROMOTION);
              // console.log(this.form.attachment[4]);
              this.form.attachment[4].url =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.prAttchId;
              this.form.attachment[4].filename = this.GET_PROMOTION.prAttchNm;
            }
            if (
              this.GET_PROMOTION.docAddvTitle !== null &&
              this.GET_PROMOTION.docAddvTitle !== ""
            ) {
              this.form.editor.title = this.GET_PROMOTION.docAddvTitle;
              this.form.editor.content = this.GET_PROMOTION.docAddvConts;
              this.$nextTick((_) => {
                this.view.isEditorMenu = true;
              });
            }
            this.form.attachment[0].isDeleted = 0;
            this.form.attachment[1].isDeleted = 0;
            this.form.attachment[2].isDeleted = 0;
            this.form.attachment[3].isDeleted = 0;
            this.form.attachment[4].isDeleted = 0;

            this.form.fax.value =
              this.GET_PROMOTION.hospFaxNo === null
                ? ""
                : this.phoneFomatter(this.GET_PROMOTION.hospFaxNo);
            this.form.fax.flag =
              this.GET_PROMOTION.hospFaxNo !== null ? true : false;
            this.form.introduceHospital.value =
              this.GET_PROMOTION.hospIntro === null
                ? ""
                : this.GET_PROMOTION.hospIntro;
            this.form.introduceHospital.flag =
              this.GET_PROMOTION.hospIntro !== null ? true : false;
            this.form.introduceHospitalDetail.value =
              this.GET_PROMOTION.conConts === null
                ? ""
                : this.GET_PROMOTION.conConts;
            this.form.introduceHospitalDetail.flag =
              this.GET_PROMOTION.conConts !== null ? true : false;
            this.form.introduceDoctor.value =
              this.GET_PROMOTION.doctIntro === null
                ? ""
                : this.GET_PROMOTION.doctIntro;
            this.form.introduceDoctor.flag =
              this.GET_PROMOTION.doctIntro !== null ? true : false;
            this.form.historyDoctor.value =
              this.GET_PROMOTION.drHist === null
                ? ""
                : this.GET_PROMOTION.drHist;
            this.form.historyDoctor.flag =
              this.GET_PROMOTION.drHist !== null ? true : false;
            this.form.treatmentTime.value =
              this.GET_PROMOTION.etcConts === null
                ? ""
                : this.GET_PROMOTION.etcConts;
            this.form.treatmentTime.flag =
              this.GET_PROMOTION.etcConts !== null ? true : false;
            this.form.homepage.value =
              this.GET_PROMOTION.homepage === null
                ? ""
                : this.GET_PROMOTION.homepage;
            this.form.homepage.flag =
              this.GET_PROMOTION.homepage !== null ? true : false;
            this.form.subPromotionPageList.item[0].type =
              this.GET_PROMOTION.urlNm1 === null
                ? ""
                : this.GET_PROMOTION.urlNm1;
            this.form.subPromotionPageList.item[0].value =
              this.GET_PROMOTION.url1 === null ? "" : this.GET_PROMOTION.url1;
            this.form.subPromotionPageList.item[0].flag =
              this.GET_PROMOTION.url1 !== null ? true : false;
            this.form.subPromotionPageList.item[1].type =
              this.GET_PROMOTION.urlNm2 === null
                ? ""
                : this.GET_PROMOTION.urlNm2;
            this.form.subPromotionPageList.item[1].value =
              this.GET_PROMOTION.url2 === null ? "" : this.GET_PROMOTION.url2;
            this.form.subPromotionPageList.item[1].flag =
              this.GET_PROMOTION.url2 !== null ? true : false;
            this.form.subPromotionPageList.item[2].type =
              this.GET_PROMOTION.urlNm3 === null
                ? ""
                : this.GET_PROMOTION.urlNm3;
            this.form.subPromotionPageList.item[2].value =
              this.GET_PROMOTION.url3 === null ? "" : this.GET_PROMOTION.url3;
            this.form.subPromotionPageList.item[2].flag =
              this.GET_PROMOTION.url3 !== null ? true : false;
            this.form.resvPage.value =
              this.GET_PROMOTION.rsvUrl === null
                ? ""
                : this.GET_PROMOTION.rsvUrl;
            this.form.resvPage.flag =
              this.GET_PROMOTION.rsvUrl !== null ? true : false;
            this.setSelect("templateType", this.GET_PROMOTION.hospIntroType);
          } else {
            this.view.isModifyMode = false;
            this.view.btnName = "저장";
            this.view.pageOrder = null;
            this.loadRef(0);
            if (
              this.GET_PROMOTION.mapAttchId !== null &&
              this.GET_PROMOTION.mapAttchId !== ""
            ) {
              this.form.attachment[3].url =
                "https://www.drrefer.net/api/pic/" +
                this.GET_PROMOTION.mapAttchId;
              this.form.attachment[3].isDeleted = 0;
              this.form.attachment[3].filename = this.GET_PROMOTION.mapAttchNm;
              this.imageMap[3].value = this.GET_PROMOTION.mapAttchNm;
            }
          }
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
    checkReservationPage() {
      if (this.form.resvPage.value !== null) {
        this.form.resvPage.flag = false;
        const ResvPage = this.form.resvPage.value.replace(/\s/gi, "");
        if (ResvPage === "") {
          this.form.resvPage.flag = false;
          return false;
        }
        this.form.resvPage.flag = true;
        return true;
      }
    },
    onFileChange(order, event) {
      const getFile = event.target.files;
      const getFileUrl = URL.createObjectURL(event.target.files[0]);
      // console.log("가져올 파일명: ", getFile[0].name);
      // console.log("가져올 파일의 URL", getFileUrl);
      this.form.attachment[order].source = event.target.files[0]; ///////////check sein 03-25
      /** getFile */
      this.form.attachment[order].url = getFileUrl;
      this.form.attachment[order].filename = getFile[0].name;
      this.form.attachment[order].flag = true;
      this.form.attachment[order].isDeleted = 0;
      // console.log("order", this.form.attachment[order].flag);
      // console.log("파일", event.target.files[0]);
      // console.log("소스", this.form.attachment[order].source);
    },
    attachmentFileRepeat(order, type) {
      // console.log("=--------====================");
      // console.log("order Repeat: ", order);
      let parentElement;
      if (type !== undefined) {
        parentElement = document.querySelector(".wholeImg");
      } else {
        parentElement =
          document.querySelector(".document").childNodes[order + 1];
      }

      parentElement.querySelector(".file-upload").click();
    },
    attachmentFile(order) {
      if (order === 0) {
        document.querySelector(".profile .file-upload").click();
      } else if (order === 1) {
        document.querySelector(".hospital-logo .file-upload").click();
      } else if (order === 2) {
        document.querySelector(".hsPicrue .file-upload").click();
      } else if (order === 3) {
        document.querySelector(".hsMap .file-upload").click();
      }
    },
    checkFlag() {
      if (
        !this.form.tel.flag ||
        !this.form.introduceHospital.flag ||
        !this.form.introduceDoctor.flag ||
        !this.form.treatmentTime.flag ||
        !this.form.templateType.flag
      ) {
        this.SET_POPUP(true);
        this.popupSet.title = "입력값 확인";
        this.popupSet.content =
          "기입되지 않았거나 잘못 기입된 항목이 있습니다. 확인해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        if (!this.form.tel.flag) {
          this.form.tel.hasError = true;
        }
        if (!this.form.introduceHospital.flag) {
          this.form.introduceHospital.hasError = true;
        }
        if (!this.form.introduceDoctor.flag) {
          this.form.introduceDoctor.hasError = true;
        }
        if (!this.form.treatmentTime.flag) {
          this.form.treatmentTime.hasError = true;
        }
        if (!this.form.templateType.flag) {
          this.form.templateType.hasError = true;
        }
        return false;
      }
      return true;
    },
    async storeInView(type, viewOrder) {
      try {
        const prParam = {
          drId: localStorage.getItem("drId"),
          isDeletedDrPicAttchId: this.form.attachment[0].isDeleted,
          isDeletedLogoAttchId: this.form.attachment[1].isDeleted,
          isDeletedPicAttchId: this.form.attachment[2].isDeleted,
          isDeletedMapAttchId: this.form.attachment[3].isDeleted,
          isDeletedPrAttchId: this.form.attachment[4].isDeleted,
          hospTel: this.form.tel.value.replace(/-/gi, ""),
          hospFaxNo: this.form.fax.value.replace(/-/gi, ""),
          hospIntro: this.form.introduceHospital.value,
          conConts: this.form.introduceHospitalDetail.value,
          doctIntro: this.form.introduceDoctor.value,
          drHist: this.form.historyDoctor.value,
          etcConts: this.form.treatmentTime.value,
          homepage:
            this.form.homepage.value === "" || this.form.homepage.value === null
              ? null
              : this.form.homepage.value,
          // url1:
          //   this.form.subPromotionPageList.item[0].value === "" ||
          //   this.form.subPromotionPageList.item[0].value === null
          //     ? null
          //     : this.form.subPromotionPageList.item[0].value,
          // urlNm1:
          //   this.form.subPromotionPageList.item[0].type === "항목 선택"
          //     ? null
          //     : this.form.subPromotionPageList.item[0].type,
          // url2:
          //   this.form.subPromotionPageList.item[1].value === "" ||
          //   this.form.subPromotionPageList.item[1].value === null
          //     ? null
          //     : this.form.subPromotionPageList.item[1].value,
          // urlNm2:
          //   this.form.subPromotionPageList.item[1].type === "항목 선택"
          //     ? null
          //     : this.form.subPromotionPageList.item[1].type,
          // url3:
          //   this.form.subPromotionPageList.item[2].value === "" ||
          //   this.form.subPromotionPageList.item[2].value === null
          //     ? null
          //     : this.form.subPromotionPageList.item[2].value,
          // urlNm3:
          //   this.form.subPromotionPageList.item[2].type === "항목 선택"
          //     ? null
          //     : this.form.subPromotionPageList.item[2].type,
          rsvUrl:
            this.form.resvPage.value === "" || this.form.resvPage.value === null
              ? null
              : this.form.resvPage.value,
          mapAttchId: this.GET_PROMOTION.mapAttchId,
          logoAttchId: this.GET_PROMOTION.logoAttchId,
          picAttchId: this.GET_PROMOTION.picAttchId,
          drPicAttchId: this.GET_PROMOTION.drPicAttchId,
          prAttchId: this.GET_PROMOTION.prAttchId,
          mapType:
            this.form.attachment[3].source !== null
              ? "U"
              : this.GET_PROMOTION.mapType,
          hospIntroType: viewOrder,
        };
        if (this.view.isEditorMenu) {
          if (this.GET_PROMOTION.docAddvTitle !== null) {
            prParam.docAddvTitle = this.form.editor.title;
            prParam.docAddvConts = this.form.editor.content;
          } else {
            if (this.form.editor.title !== "") {
              prParam.docAddvTitle = this.form.editor.title;
              prParam.docAddvConts = this.form.editor.content;
            }
          }
        }
        if (
          prParam.hospIntroType === 3 &&
          this.form.attachment[0].url === null
        ) {
          // console.log(this.form.attachment[0]);
          prParam.hospIntroType = 4;
        }
        //console.log("파람데이터 :", prParam);

        // 유효성 검사

        // 1. 아이디
        if (prParam.drId === null || prParam.drId === undefined)
          prParam.drId = "";

        var drIdBoo = /^[0-9|a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|!|]{2,20}$/;

        if (!drIdBoo.test(prParam.drId)) {
          this.SET_POPUP(true);
          this.popupSet.title = "로그인 확인";
          this.popupSet.content =
            "로그인이 정상적이지 않습니다. \n 로그아웃 및 재로그인을 해주시기 바랍니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 2. isDeletedDrPicAttchId
        if (
          prParam.isDeletedDrPicAttchId === null ||
          prParam.isDeletedDrPicAttchId === undefined
        )
          prParam.isDeletedDrPicAttchId = "";

        if (
          prParam.isDeletedDrPicAttchId.length < 0 ||
          prParam.isDeletedDrPicAttchId.length > 40
        ) {
          this.SET_POPUP(true);
          this.popupSet.title = "오류";
          this.popupSet.content = "오류 발생";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 3. isDeletedLogoAttchId
        if (
          prParam.isDeletedLogoAttchId === null ||
          prParam.isDeletedLogoAttchId === undefined
        )
          prParam.isDeletedLogoAttchId = "";

        if (
          prParam.isDeletedLogoAttchId.length < 0 ||
          prParam.isDeletedLogoAttchId.length > 40
        ) {
          this.SET_POPUP(true);
          this.popupSet.title = "오류";
          this.popupSet.content = "오류 발생";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 4. isDeletedPicAttchId
        if (
          prParam.isDeletedPicAttchId === null ||
          prParam.isDeletedPicAttchId === undefined
        )
          prParam.isDeletedPicAttchId = "";

        if (
          prParam.isDeletedPicAttchId.length < 0 ||
          prParam.isDeletedPicAttchId.length > 40
        ) {
          this.SET_POPUP(true);
          this.popupSet.title = "오류";
          this.popupSet.content = "오류 발생";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 5. isDeletedMapAttchId
        if (
          prParam.isDeletedMapAttchId === null ||
          prParam.isDeletedMapAttchId === undefined
        )
          prParam.isDeletedMapAttchId = "";

        if (
          prParam.isDeletedMapAttchId.length < 0 ||
          prParam.isDeletedMapAttchId.length > 40
        ) {
          this.SET_POPUP(true);
          this.popupSet.title = "오류";
          this.popupSet.content = "오류 발생";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 6. isDeletedPrAttchId
        if (
          prParam.isDeletedPrAttchId === null ||
          prParam.isDeletedPrAttchId === undefined
        )
          prParam.isDeletedPrAttchId = "";

        if (
          prParam.isDeletedPrAttchId.length < 0 ||
          prParam.isDeletedPrAttchId.length > 40
        ) {
          this.SET_POPUP(true);
          this.popupSet.title = "오류";
          this.popupSet.content = "오류 발생";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        //  7. 전화번호
        if (prParam.hospTel === null || prParam.hospTel === undefined)
          prParam.hospTel = "";

        var hospTelBoo = /^[0-9]{8,14}$/;

        if (!hospTelBoo.test(prParam.hospTel.replace(/-/gi, ""))) {
          this.SET_POPUP(true);
          this.popupSet.title = "전화번호 확인";
          this.popupSet.content = "전화번호의 숫자를 확인해주세요";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 8. 팩스번호
        if (prParam.hospFaxNo === null || prParam.hospFaxNo === undefined)
          prParam.hospFaxNo = "";

        var hospFaxNoBoo = /^[0-9]{0,14}$/;

        if (!hospFaxNoBoo.test(prParam.hospFaxNo.replace(/-/gi, ""))) {
          this.SET_POPUP(true);
          this.popupSet.title = "팩스번호 확인";
          this.popupSet.content = "팩스번호의 숫자를 확인해주세요";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 9. 병원소개
        if (prParam.hospIntro === null || prParam.hospIntro === undefined)
          prParam.hospIntro = "";

        if (prParam.hospIntro.length < 0 || prParam.hospIntro.length > 3000) {
          this.SET_POPUP(true);
          this.popupSet.title = "병원소개 확인";
          this.popupSet.content = "병원소개를 3000자 이하로 입력해주세요";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 10. 진료시간
        if (prParam.conConts === null || prParam.conConts === undefined)
          prParam.conConts = "";

        if (prParam.conConts.length < 0 || prParam.conConts.length > 3000) {
          this.SET_POPUP(true);
          this.popupSet.title = "진료시간 확인";
          this.popupSet.content = "진료시간를 3000자 이하로 입력해주세요";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 11. 의사소개
        if (prParam.doctIntro === null || prParam.doctIntro === undefined)
          prParam.doctIntro = "";

        if (prParam.doctIntro.length < 0 || prParam.doctIntro.length > 3000) {
          this.SET_POPUP(true);
          this.popupSet.title = "의사소개 확인";
          this.popupSet.content = "의사소개를 3000자 이하로 입력해주세요";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 12. 의사약력
        if (prParam.drHist === null || prParam.drHist === undefined)
          prParam.drHist = "";

        if (prParam.drHist.length < 0 || prParam.drHist.length > 3000) {
          this.SET_POPUP(true);
          this.popupSet.title = "의사약력 확인";
          this.popupSet.content = "의사약력을 3000자 이하로 입력해주세요";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 13. 기타사항
        if (prParam.etcConts === null || prParam.etcConts === undefined)
          prParam.etcConts = "";

        if (prParam.etcConts.length < 0 || prParam.etcConts.length > 3000) {
          this.SET_POPUP(true);
          this.popupSet.title = "기타사항 확인";
          this.popupSet.content = "기타사항을 3000자 이하로 입력해주세요";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        //14. 홈페이지주소
        if (prParam.homepage === null || prParam.homepage === undefined)
          prParam.homepage = "";

        if (prParam.homepage.length < 0 || prParam.homepage.length > 200) {
          this.SET_POPUP(true);
          this.popupSet.title = "홈페이지주소 확인";
          this.popupSet.content = "홈페이지주소를 200자 이하로 입력해주세요";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 15. 기타url1

        // if (prParam.url1 === null || prParam.url1 === undefined)
        //   prParam.url1 = "";

        // if (prParam.url1.length < 0 || prParam.url1.length > 200) {
        //   this.SET_POPUP(true);
        //   this.popupSet.title = "기타1 주소 확인";
        //   this.popupSet.content = "기타1 주소를 200자 이하로 입력해주세요";
        //   this.popupSet.popType = "warn";
        //   this.popupSet.cancelBtnText = "확인";
        //   return;
        // }

        // 16. 기타url1명
        // if (prParam.urlNm1 === null || prParam.urlNm1 === undefined)
        //   prParam.urlNm1 = "";

        // if (prParam.urlNm1.length < 0 || prParam.urlNm1.length > 100) {
        //   this.SET_POPUP(true);
        //   this.popupSet.title = "기타1 주소명 확인";
        //   this.popupSet.content = "기타1 주소명을 100자 이하로 입력해주세요";
        //   this.popupSet.popType = "warn";
        //   this.popupSet.cancelBtnText = "확인";
        //   return;
        // }

        // 17. 기타url2
        // if (prParam.url2 === null || prParam.url2 === undefined)
        //   prParam.url2 = "";

        // if (prParam.url2.length < 0 || prParam.url2.length > 200) {
        //   this.SET_POPUP(true);
        //   this.popupSet.title = "기타2 주소 확인";
        //   this.popupSet.content = "기타2 주소를 200자 이하로 입력해주세요";
        //   this.popupSet.popType = "warn";
        //   this.popupSet.cancelBtnText = "확인";
        //   return;
        // }

        // 18. 기타url2명
        // if (prParam.urlNm2 === null || prParam.urlNm2 === undefined)
        //   prParam.urlNm2 = "";

        // if (prParam.urlNm2.length < 0 || prParam.urlNm2.length > 100) {
        //   this.SET_POPUP(true);
        //   this.popupSet.title = "기타2 주소명 확인";
        //   this.popupSet.content = "기타2 주소명을 100자 이하로 입력해주세요";
        //   this.popupSet.popType = "warn";
        //   this.popupSet.cancelBtnText = "확인";
        //   return;
        // }

        // // 19. 기타url3
        // if (prParam.url3 === null || prParam.url3 === undefined)
        //   prParam.url3 = "";

        // if (prParam.url3.length < 0 || prParam.url3.length > 200) {
        //   this.SET_POPUP(true);
        //   this.popupSet.title = "기타3 주소 확인";
        //   this.popupSet.content = "기타3 주소를 200자 이하로 입력해주세요";
        //   this.popupSet.popType = "warn";
        //   this.popupSet.cancelBtnText = "확인";
        //   return;
        // }

        // // 20. 기타url3명
        // if (prParam.urlNm3 === null || prParam.urlNm3 === undefined)
        //   prParam.urlNm3 = "";

        // if (prParam.urlNm3.length < 0 || prParam.urlNm3.length > 100) {
        //   this.SET_POPUP(true);
        //   this.popupSet.title = "기타3 주소명 확인";
        //   this.popupSet.content = "기타3 주소명을 100자 이하로 입력해주세요";
        //   this.popupSet.popType = "warn";
        //   this.popupSet.cancelBtnText = "확인";
        //   return;
        // }

        // 21. 예약URL
        if (prParam.rsvUrl === null || prParam.rsvUrl === undefined)
          prParam.rsvUrl = "";

        if (prParam.rsvUrl.length < 0 || prParam.rsvUrl.length > 200) {
          this.SET_POPUP(true);
          this.popupSet.title = "예약 앱/웹 주소 확인";
          this.popupSet.content = "예약 앱/웹 주소를 200자 이하로 입력해주세요";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 22. 약도첨부파일번호
        if (prParam.mapAttchId === null || prParam.mapAttchId === undefined)
          prParam.mapAttchId = "";

        if (prParam.mapAttchId.length < 0 || prParam.mapAttchId.length > 40) {
          this.SET_POPUP(true);
          this.popupSet.title = "첨부 오류";
          this.popupSet.content = "첨부 오류";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 23. 로고첨부파일번호
        if (prParam.logoAttchId === null || prParam.logoAttchId === undefined)
          prParam.logoAttchId = "";

        if (prParam.logoAttchId.length < 0 || prParam.logoAttchId.length > 40) {
          this.SET_POPUP(true);
          this.popupSet.title = "첨부 오류";
          this.popupSet.content = "첨부 오류";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 24. 사진첨부파일번호
        if (prParam.picAttchId === null || prParam.picAttchId === undefined)
          prParam.picAttchId = "";

        if (prParam.picAttchId.length < 0 || prParam.picAttchId.length > 40) {
          this.SET_POPUP(true);
          this.popupSet.title = "첨부 오류";
          this.popupSet.content = "첨부 오류";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 25. 의사사진첨부파일번호
        if (prParam.drPicAttchId === null || prParam.drPicAttchId === undefined)
          prParam.drPicAttchId = "";

        if (
          prParam.drPicAttchId.length < 0 ||
          prParam.drPicAttchId.length > 40
        ) {
          this.SET_POPUP(true);
          this.popupSet.title = "첨부 오류";
          this.popupSet.content = "첨부 오류";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 26. 소개첨부파일번호
        if (prParam.prAttchId === null || prParam.prAttchId === undefined)
          prParam.prAttchId = "";

        if (prParam.prAttchId.length < 0 || prParam.prAttchId.length > 40) {
          this.SET_POPUP(true);
          this.popupSet.title = "첨부 오류";
          this.popupSet.content = "첨부 오류";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 27. 약도구분
        if (prParam.mapType === null || prParam.mapType === undefined)
          prParam.mapType = "";

        var mapTypeBoo = /^[A-Z]{0,1}$/;

        if (!mapTypeBoo.test(prParam.mapType)) {
          this.SET_POPUP(true);
          (this.popupSet.title = "약도구분 오류"),
            (this.popupSet.content = "약도구분 오류"),
            (this.popupSet.popType = "warn");
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 28. 소개글타입
        if (
          prParam.hospIntroType === null ||
          prParam.hospIntroType === undefined
        )
          prParam.hospIntroType = "";

        var hospIntroTypeBoo = /^[0-9]{0,1}$/;

        if (!hospIntroTypeBoo.test(prParam.hospIntroType)) {
          this.SET_POPUP(true);
          (this.popupSet.title = "소개글타입 오류"),
            (this.popupSet.content = "소개글타입 오류"),
            (this.popupSet.popType = "warn");
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 29. 소개정보 추가 내용
        if (prParam.docAddvConts === null || prParam.docAddvConts === undefined)
          prParam.docAddvConts = "";

        if (
          prParam.docAddvConts.length < 0 ||
          prParam.docAddvConts.length > 3000
        ) {
          this.SET_POPUP(true);
          this.popupSet.title = "소개정보 추가 내용 오류";
          this.popupSet.content =
            "3000자 이내로 소개정보 추가 내용을 입력해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        // 30. 소개정보 추가 제목
        if (prParam.docAddvTitle === null || prParam.docAddvTitle === undefined)
          prParam.docAddvTitle = "";

        if (
          prParam.docAddvTitle.length < 0 ||
          prParam.docAddvTitle.length > 100
        ) {
          this.SET_POPUP(true);
          this.popupSet.title = "소개정보 추가 제목 오류";
          this.popupSet.content =
            "100자 이내로 소개정보 추가 제목을 입력해주세요.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
          return;
        }

        /** 벡엔드 스트링으로 파싱하기 때문에 직렬화를해준다. */
        const transedPrParam = JSON.stringify(prParam);
        const transData = new FormData();
        transData.append("prParam", transedPrParam);
        if (type) {
          transData.append(
            "drPicFile",
            this.form.attachment[0].source === null
              ? this.GET_PROMOTION.drPicAttchId
              : this.form.attachment[0].source
          );
          transData.append(
            "logoFile",
            this.form.attachment[1].source === null
              ? this.GET_PROMOTION.logoAttchId
              : this.form.attachment[1].source
          );
          transData.append(
            "picFile",
            this.form.attachment[2].source === null
              ? this.GET_PROMOTION.picAttchId
              : this.form.attachment[2].source
          );
          transData.append(
            "prFile",
            this.form.attachment[4].source === null
              ? this.GET_PROMOTION.picAttchId
              : this.form.attachment[4].source
          );
        } else {
          if (this.form.attachment[0].source !== null) {
            transData.append("drPicFile", this.form.attachment[0].source);
          }
          if (this.form.attachment[1].source !== null) {
            transData.append("logoFile", this.form.attachment[1].source);
          }
          if (this.form.attachment[2].source !== null) {
            transData.append("picFile", this.form.attachment[2].source);
          }
          if (this.form.attachment[4].source !== null) {
            transData.append("prFile", this.form.attachment[4].source);
          }
        }
        transData.append(
          "mapFile",
          this.form.attachment[3].source === null
            ? this.GET_PROMOTION.mapAttchId
            : this.form.attachment[3].source
        );
        // console.log("전송할 데이터(전체) :", transData);

        if (type) {
          await this.PROMOTION_MODIFY(transData).then(() => {
            this.SET_POPUP(true);
            this.popupSet.title = "소개정보 등록성공";
            this.popupSet.content =
              `선택하신 유형의 소개페이지로 저장되었습니다. \n <b class="point">https://www.drrefer.net/dr/` +
              localStorage.getItem("drId") +
              "</b> 에서 \n 선택하신 내용의  변경사항을 확인하실 수 있습니다.\n 다른 의료진의 소개페이지 클릭 시 작성하신 내용이 반영됩니다.";
            /** 
            this.popupSet.popType = "custom";
            this.popupSet.cancelBtnText = undefined;
            this.popupSet.confirmBtnText = "확인";
            this.popupSet.nextLink = "/v/request/list";
            this.popupSet.destination = true;
            */
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          });
        } else {
          await this.PROMOTION_SUBMIT(transData).then(() => {
            this.checkPrData();
            if (!this.isDetermined) {
              this.SET_POPUP(true);
              this.popupSet.title = "소개정보 등록성공";
              (this.popupSet.content =
                "아래 항목을 입력하고 저장을 누르면,\n병원 및 회원님의 소개 페이지가 자동으로 완성됩니다.\n입력전 우측의 Type A~E를 선택하여 대략적인 디자인을 확인할 수 있습니다\n\nType A~C는 미리 디자인된 Sample Form이며\nTypeD는 직접 디자인 완료한 이미지 파일을 업로드 할 수 있습니다.\nType E는 홈페이지 주소와 준비중 안내를 게시할 수 있습니다.\n**소개정보를 입력하지 않을시 자동으로 Type E Form으로 저장됩니다."),
                /** 
              this.popupSet.popType = "custom";
              this.popupSet.cancelBtnText = undefined;
              this.popupSet.confirmBtnText = "확인";
              this.popupSet.nextLink = "/v/request/list";
              this.popupSet.destination = true;
               */
                (this.popupSet.popType = "warn");
              this.popupSet.cancelBtnText = "확인";
            } else {
              this.$router.push("/v/join/finish").catch(() => {});
            }
          });
        }
        this.$emit("sidebar-update-method");
      } catch (error) {
        console.log("error: ", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "소개정보 등록실패";
          this.popupSet.content = " 소개정보를 등록할 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    async store(type) {
      try {
        const prParam = {
          drId: localStorage.getItem("drId"),
          isDeletedDrPicAttchId: this.form.attachment[0].isDeleted,
          isDeletedLogoAttchId: this.form.attachment[1].isDeleted,
          isDeletedPicAttchId: this.form.attachment[2].isDeleted,
          isDeletedMapAttchId: this.form.attachment[3].isDeleted,
          isDeletedPrAttchId: this.from.attachment[4].isDeleted,
          hospTel: this.form.tel.value.replace(/-/gi, ""),
          hospFaxNo: this.form.fax.value.replace(/-/gi, ""),
          hospIntro: this.form.introduceHospital.value,
          conConts: this.form.introduceHospitalDetail.value,
          doctIntro: this.form.introduceDoctor.value,
          drHist: this.form.historyDoctor.value,
          etcConts: this.form.treatmentTime.value,
          homepage:
            this.form.homepage.value === "" || this.form.homepage.value === null
              ? null
              : this.form.homepage.value,
          url1:
            this.form.subPromotionPageList.item[0].value === "" ||
            this.form.subPromotionPageList.item[0].value === null
              ? null
              : this.form.subPromotionPageList.item[0].value,
          urlNm1:
            this.form.subPromotionPageList.item[0].type === "항목 선택"
              ? null
              : this.form.subPromotionPageList.item[0].type,
          url2:
            this.form.subPromotionPageList.item[1].value === "" ||
            this.form.subPromotionPageList.item[1].value === null
              ? null
              : this.form.subPromotionPageList.item[1].value,
          urlNm2:
            this.form.subPromotionPageList.item[1].type === "항목 선택"
              ? null
              : this.form.subPromotionPageList.item[1].type,
          url3:
            this.form.subPromotionPageList.item[2].value === "" ||
            this.form.subPromotionPageList.item[2].value === null
              ? null
              : this.form.subPromotionPageList.item[2].value,
          urlNm3:
            this.form.subPromotionPageList.item[2].type === "항목 선택"
              ? null
              : this.form.subPromotionPageList.item[2].type,
          rsvUrl:
            this.form.resvPage.value === "" || this.form.resvPage.value === null
              ? null
              : this.form.resvPage.value,
          mapAttchId: this.GET_PROMOTION.mapAttchId,
          logoAttchId: this.GET_PROMOTION.logoAttchId,
          picAttchId: this.GET_PROMOTION.picAttchId,
          drPicAttchId: this.GET_PROMOTION.drPicAttchId,
          prAttchId: this.GET_PROMOTION.prAttchId,
          mapType:
            this.form.attachment[3].source !== null
              ? "U"
              : this.GET_PROMOTION.mapType,
          hospIntroType: this.transedType(this.form.templateType.value),
        };
        if (
          prParam.hospIntroType === 3 &&
          this.form.attachment[0].source === null
        ) {
          prParam.hospIntroType = 0;
          // console.log("이거 뭔지 체크1");
        }
        if (this.view.isEditorMenu) {
          if (this.GET_PROMOTION.docAddvTitle !== null) {
            prParam.docAddvTitle = this.form.editor.title;
            prParam.docAddvConts = this.form.editor.content;
          } else {
            if (this.form.editor.title !== "") {
              prParam.docAddvTitle = this.form.editor.title;
              prParam.docAddvConts = this.form.editor.content;
            }
          }
        }
        if (
          prParam.hospIntroType === 3 &&
          this.form.attachment[0].source === null
        ) {
          prParam.hospIntroType = 0;
        }
        // console.log("파람데이터 :", prParam);
        /** 벡엔드 스트링으로 파싱하기 때문에 직렬화를해준다. */
        const transedPrParam = JSON.stringify(prParam);
        const transData = new FormData();
        transData.append("prParam", transedPrParam.toString());
        if (type) {
          transData.append(
            "drPicFile",
            this.form.attachment[0].source === null
              ? this.GET_PROMOTION.drPicAttchId
              : this.form.attachment[0].source
          );
          transData.append(
            "logoFile",
            this.form.attachment[1].source === null
              ? this.GET_PROMOTION.logoAttchId
              : this.form.attachment[1].source
          );
          transData.append(
            "picFile",
            this.form.attachment[2].source === null
              ? this.GET_PROMOTION.picAttchId
              : this.form.attachment[2].source
          );
          transData.append(
            "prFile",
            this.form.attachment[4].source === null
              ? this.GET_PROMOTION.picAttchId
              : this.form.attachment[4].source
          );
        } else {
          if (this.form.attachment[0].source !== null) {
            transData.append("drPicFile", this.form.attachment[0].source);
          }
          if (this.form.attachment[1].source !== null) {
            transData.append("logoFile", this.form.attachment[1].source);
          }
          if (this.form.attachment[2].source !== null) {
            transData.append("picFile", this.form.attachment[2].source);
          }
          if (this.form.attachment[4].source !== null) {
            transData.append("prFile", this.form.attachment[4].source);
          }
        }
        transData.append(
          "mapFile",
          this.form.attachment[3].source === null
            ? this.GET_PROMOTION.mapAttchId
            : this.form.attachment[3].source
        );
        // console.log("전송할 데이터(전체) :", transData);
        if (type) {
          await this.PROMOTION_MODIFY(transData).then(() => {
            this.SET_POPUP(true);
            this.popupSet.title = "소개정보 등록성공";
            (this.popupSet.content =
              "아래 항목을 입력하고 저장을 누르면,\n병원 및 회원님의 소개 페이지가 자동으로 완성됩니다.\n입력전 우측의 Type A~E를 선택하여 대략적인 디자인을 확인할 수 있습니다\n\nType A~C는 미리 디자인된 Sample Form이며\nTypeD는 직접 디자인 완료한 이미지 파일을 업로드 할 수 있습니다.\nType E는 홈페이지 주소와 준비중 안내를 게시할 수 있습니다.\n**소개정보를 입력하지 않을시 자동으로 Type E Form으로 저장됩니다."),
              /** 
            this.popupSet.popType = "custom";
            this.popupSet.cancelBtnText = undefined;
            this.popupSet.confirmBtnText = "확인";
            this.popupSet.nextLink = "/v/request/list";
            this.popupSet.destination = true;
            */
              (this.popupSet.popType = "warn");
            this.popupSet.cancelBtnText = "확인";
          });
        } else {
          await this.PROMOTION_SUBMIT(transData).then(() => {
            this.checkPrData();
            if (!this.isDetermined) {
              this.SET_POPUP(true);
              this.popupSet.title = "소개정보 등록성공";
              (this.popupSet.content =
                "아래 항목을 입력하고 저장을 누르면,\n병원 및 회원님의 소개 페이지가 자동으로 완성됩니다.\n입력전 우측의 Type A~E를 선택하여 대략적인 디자인을 확인할 수 있습니다\n\nType A~C는 미리 디자인된 Sample Form이며\nTypeD는 직접 디자인 완료한 이미지 파일을 업로드 할 수 있습니다.\nType E는 홈페이지 주소와 준비중 안내를 게시할 수 있습니다.\n**소개정보를 입력하지 않을시 자동으로 Type E Form으로 저장됩니다."),
                /** 
              this.popupSet.popType = "custom";
              this.popupSet.cancelBtnText = undefined;
              this.popupSet.confirmBtnText = "확인";
              this.popupSet.nextLink = "/v/request/list";
              this.popupSet.destination = true;
              */
                (this.popupSet.popType = "warn");
              this.popupSet.cancelBtnText = "확인";
            } else {
              this.$router.push("/v/join/finish").catch(() => {});
            }
          });
        }
      } catch (error) {
        console.log("error: ", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "소개정보 등록실패";
          this.popupSet.content = " 검색정보를 등록할 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    transedType(value) {
      if (value === "C형" || value === "항목 선택") {
        return 4;
      } else if (value === "D형") {
        return 4;
      } else if (value === "E형") {
        return 4;
      } else if (value === "B형") {
        return 3;
      } else if (value === "A형") {
        return 4;
      } else {
        return 5;
      }
    },
    loadPr(order) {
      window.scrollTo(0, 0);
      // console.log("순번Pr", order);
      this.view.pageOrder = order;
      this.$nextTick((_) => {
        if (order !== 1) {
          this.prePareTextarea();
        } else {
          this.prePareTextareaTypeB();
        }
      });
      this.$refs.float.markItem(this.view.pageOrder);
    },
    loadRef(order, sampleNum) {
      // console.log("순번 Ref", order);
      if (order === 0) {
        this.view.isFooterVisible = true;
        this.view.pageOrder = null;
        this.$nextTick((_) => {
          this.prePareTextarea();
        });
      } else if (order === 1) {
        this.view.pageOrder = sampleNum;
      }
    },
  },
  destroyed() {
    this.setCount = false;
    this.SET_PASSED_URL(null);
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/PrManage";
input,
textarea {
  -moz-user-select: auto;

  -webkit-user-select: auto;

  -ms-user-select: auto;

  user-select: auto;
}
.custom-table {
  background-color: #f5f9fa;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}
.custom-table-cell {
  align-items: center;
  display: flex;
}
.custom-table-row {
  border-bottom: 1px solid lightgrey;
}
.background-cell {
  background-color: white;
  min-height: 120px;
}
.pr-manage-sample-btn {
  display: inline-block;
  width: 116px;
  &:last-child {
    margin-left: 10px;
    width: 150px;
  }
}
.pr-manage-radio {
  display: inline-block;
}
.pr-manage-sam-pre-div {
  display: inline-block;
}

.editor-form { 
  -moz-user-select: auto;

  -webkit-user-select: auto;

  -ms-user-select: auto;

  user-select: auto;
}

.ck-rounded-corners {
  -moz-user-select: auto;

  -webkit-user-select: auto;

  -ms-user-select: auto;

  user-select: auto;
}

@media screen and (max-width: 1100px) {
  .pr-manage-sam-pre-div {
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 958px) {
  .pr-manage-sam-pre-div {
    display: inline-block;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 570px) {
  .pr-manage-sam-pre-div {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 338px) {
  .pr-manage-sam-pre-div {
    text-align: center;
  }
  .pr-manage-sample-btn {
    &:first-child {
      margin-bottom: 15px;
    }
  }
}
</style>
